import styled from "@emotion/styled"

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: flex-end;
  padding-right: 20px;
  row-gap: 10px;
  width: 100%;
`

export const TotalPrice = styled.h2`
  font-size: 16px;
`

export const TotalPriceSubtitle = styled.h3`
  font-size: 14px;
  font-weight: 400;
`

export const TopRow = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: flex-end;
  row-gap: 2px;

  &:last-of-type {
    margin-bottom: 0;
  }

  & h2 {
    font-size: 16px;
  }

  & input:not(.MuiSelect-nativeInput) {
    position: relative;
    display: flex;
    align-items: center;
    color: #747c84;
    border: none;
    height: 50px;
    margin: auto;
    outline: none;
    padding: 8px 25px;
    font-size: 16px;
    box-shadow: 0 6px 20px rgb(214 214 214 / 75%);
    box-sizing: border-box;
    border-radius: 50px;
    background-color: white;
    -webkit-appearance: none;
    font-family: "Poppins", sans-serif;
    width: 100px;

    .svg-inline--fa {
      margin-right: 10px;
    }
  }
`
