import styled from "@emotion/styled"

export const Wrapper = styled.div`
  display: grid;
  row-gap: 10px;
  width: 400px;
`

export const Row = styled.div`
  align-items: center;
  column-gap: 10px;
  display: grid;
  grid-template-columns: auto 1fr;
`

export const Label = styled.label`
  width: 120px;
`
