import styled from "@emotion/styled"

export const Wrapper = styled.div`
  align-items: flex-end;
  display: grid;
  justify-items: flex-end;

  & h2 {
    margin-right: 5px;
  }

  & h3 {
    font-size: 14px;
    font-weight: 400;
  }

  & input {
    height: 40px;
  }
`

export const PlusMinusButtons = styled.div`
  display: flex;
  margin-left: 10px;

  & span:first-of-type {
    margin-right: 5px;
  }
`

export const Row = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 10px;

  &:last-of-type {
    margin-bottom: 0;
  }
`
