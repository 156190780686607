import { getFunctions, httpsCallable } from "firebase/functions"
import { Button } from "@litta/ui"

import { useNavigate } from "react-router-dom"

import { useRecoilState, useRecoilValue } from "recoil"
import quoteState, { QuoteState, isQuoteValidState } from "atoms/quote"

import { QuoteButtonsBottom, QuoteButtonsTop, Wrapper } from "./styled"
import useUpdateUserHistory from "hooks/useUpdateUserHistory"
import { useState } from "react"
import { useSelectedDayAndTime } from "@litta/hooks"

type QuoteStatus = "booking" | "gatheringQuotes" | "paused"

interface ButtonsProps {
  allAnswers: any
  newQuote: any
}

const Buttons = ({ allAnswers, newQuote }: ButtonsProps) => {
  const [quote, setQuote] = useRecoilState<QuoteState>(quoteState)
  const navigate = useNavigate()
  const { isQuoteValid } = useRecoilValue(isQuoteValidState)

  const [postCodeError, setPostCodeError] = useState(false)
  const [dateError, setDateError] = useState(false)

  const updateUserHistory = useUpdateUserHistory()
  const { selectedDayAndTime } = useSelectedDayAndTime()
  // const { setSelectedLastBookingTime } = useSelectedDayAndTime()

  const onCreateQuote =
    (sendQuote: boolean, options?: { [key: string]: number | string | null }) =>
    async () => {
      // if (quote.record.selected_slot_id.trim() !== "custom") {
      //   setQuote({
      //     ...quote,
      //     record: {
      //       ...quote.record,
      //       selectedLastBookingTime: null
      //     }
      //   })
      //   setSelectedLastBookingTime(null)
      // }
      setDateError(false)
      setPostCodeError(false)

      if (!quote.record.address_details.postcode) {
        setPostCodeError(true)
        return
      }

      if (!quote.record.selectedDate) {
        setDateError(true)
        return
      }

      const functions = getFunctions()
      const createQuote = httpsCallable(functions, "quote_request")
      const sendAnswer = httpsCallable(functions, "submit_confirmation")

      if (!isQuoteValid) {
        return
      }

      if (!quote.loading) {
        setQuote({
          ...quote,
          loading: true
        })
        const result: any = await createQuote({
          ...newQuote,
          status: "quotebuilder_updated",
          ...(options ?? {}),
          send_quote: sendQuote,
          WEEK_DAY_FEE:
            typeof selectedDayAndTime.dayFee !== "undefined"
              ? selectedDayAndTime.dayFee
              : 0
        })

        if (Object.keys(allAnswers).length) {
          await sendAnswer({
            id: result.data.recordId,
            fields: allAnswers
          })
        }

        updateUserHistory()

        if (result.data.status === "success") {
          setQuote({
            ...quote,
            loading: false
          })

          navigate(`/?id=${result.data.recordId}`)
        }
      }
    }

  const onUpdateQuoteStatus = (status: QuoteStatus) => () => {
    setQuote({
      ...quote,
      record: {
        ...quote.record,
        status
      }
    })

    onCreateQuote(false, {
      status
    })()
  }

  const isSendQuoteDisabled =
    quote.loading ||
    (quote.record.status === "Confirmed Fail" && !quote.record.fail_reason)

  const updateCreateQuoteLabel =
    quote.quoteType === "update" ? "Update quote" : "Create a quote"

  if (quote.record?.paid) {
    return null
  }

  return (
    <Wrapper>
      {quote.quoteType === "update" && (
        <QuoteButtonsTop>
          <Button
            disabled={quote.loading}
            label="Pause"
            loading={quote.loading}
            onClick={onUpdateQuoteStatus("paused")}
            outlined
          />
          <Button
            disabled={quote.loading}
            label="Gathering Quotes"
            loading={quote.loading}
            onClick={onUpdateQuoteStatus("gatheringQuotes")}
            outlined
          />
          <Button
            disabled={quote.loading}
            label="Booking"
            loading={quote.loading}
            onClick={onUpdateQuoteStatus("booking")}
            outlined
          />
        </QuoteButtonsTop>
      )}
      <QuoteButtonsBottom>
        <Button
          disabled={quote.loading}
          label="Send Quote"
          loading={quote.loading}
          onClick={onCreateQuote(true)}
          outlined
        />
        <Button
          disabled={isSendQuoteDisabled}
          label={updateCreateQuoteLabel}
          loading={quote.loading}
          onClick={onCreateQuote(false)}
        />
        {dateError && (
          <span style={{ color: "red", fontSize: "16px", marginTop: "1rem" }}>
            Please select date
          </span>
        )}
        {postCodeError && (
          <span style={{ color: "red", fontSize: "16px", marginTop: "1rem" }}>
            Please select full address
          </span>
        )}
      </QuoteButtonsBottom>
    </Wrapper>
  )
}

export default Buttons
