import { Label, Row, Wrapper } from "./styled"
import { useBookingTypes, useSalesOwners } from "@litta/hooks"
import Select, { SingleValue } from "react-select"
import { DropdownOption } from "@litta/types"
import UserHistory from "./UserHistory"

const styles = {
  container: (provided: any) => ({
    ...provided,
    flex: "1 1 0"
  })
}

interface MiddleProps {
  bookingType: any
  salesOwner: any
  setBookingType: any
  setSalesOwner: any
}

const Middle = ({
  bookingType,
  salesOwner,
  setBookingType,
  setSalesOwner
}: MiddleProps) => {
  const { bookingTypes, loading: bookingTypesLoading } = useBookingTypes()
  const { salesOwners, loading: salesOwnersLoading } = useSalesOwners()

  const bookingTypesOptions = bookingTypes.map((bookingType) => ({
    label: bookingType.booking_type,
    value: bookingType.booking_type
  }))
  const salesOwnersOptions = salesOwners.map((salesOwner) => ({
    label: salesOwner.name,
    value: salesOwner.userId
  }))

  const onSetBookingType = (option: SingleValue<DropdownOption>) => {
    setBookingType(option?.value)
  }
  const onSetSalesOwner = (option: SingleValue<DropdownOption>) => {
    setSalesOwner(option?.value)
  }

  return (
    <Wrapper>
      <Row>
        <Label htmlFor="salesOwner">{"Owner:"}</Label>
        <Select
          isLoading={salesOwnersLoading}
          onChange={onSetSalesOwner}
          options={salesOwnersOptions}
          styles={styles}
          value={salesOwnersOptions?.find(
            (option) => option.value === salesOwner
          )}
        />
      </Row>
      <Row>
        <Label htmlFor="bookingType">{"Booking Type:"}</Label>
        <Select
          isLoading={bookingTypesLoading}
          isSearchable={false}
          onChange={onSetBookingType}
          options={bookingTypesOptions}
          styles={styles}
          value={bookingTypesOptions?.find(
            (option) => option.value === bookingType
          )}
        />
      </Row>
      <UserHistory />
    </Wrapper>
  )
}

export default Middle
