import "./App.scss"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { store } from "../src/store"
import { Provider } from "react-redux"
import Router from "./routers"
import { RecoilRoot } from "recoil"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import { LittaThemeProvider } from "@litta/ui"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { useState } from "react"

// const theme = createTheme({
// ...uiTheme
// For when are ready for ALL Pink Theme
// palette: {
//   ...uiTheme.palette,
//   primary: {
//     ...uiTheme.palette.primary,
//     main: colors.yourPink
//   }
// }
// })

const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_KEY || ""

const queryClient = new QueryClient()

const App = () => {
  const [stripePromise] = useState(() => loadStripe(STRIPE_PUBLIC_KEY))

  return (
    <RecoilRoot>
      <Provider store={store}>
        <LittaThemeProvider>
          <QueryClientProvider client={queryClient}>
            <Elements stripe={stripePromise}>
              <div className="App">
                <Router />
              </div>
            </Elements>
          </QueryClientProvider>
        </LittaThemeProvider>
      </Provider>
    </RecoilRoot>
  )
}

export default App
