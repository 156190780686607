import { reference } from "./type"

const initialState = {
  reference: ""
}

export const ReferenceReducer: any = (
  state = initialState,
  action: { type: string | null; data: any }
) => {
  if (action.type === reference) {
    return {
      ...state,
      reference: action.data
    }
  }
  return state
}
