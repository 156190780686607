import { SelectChangeEvent } from "@mui/material/Select"

import { CollectionItem } from "@litta/types"
import { useServicePricings } from "@litta/hooks"

import { Dropdown } from "@litta/ui"
import { Wrapper } from "./styled"
import sortBy from "lodash/fp/sortBy"

const getLabel = (product: CollectionItem, isCommercialClientType: boolean) => {
  let label = ""

  label += product["LITTA Service"]

  label += ` - £${
    isCommercialClientType
      ? product?.price_ex_vat?.toFixed(2)
      : product.price_inc_vat.toFixed(2)
  }`

  return label
}

interface AdditionalTimeItemsProps {
  onAddCollectionItem: (item: CollectionItem) => void
  isCommercialClientType: boolean
}

const AdditionalTimeItems = ({
  isCommercialClientType,
  onAddCollectionItem
}: AdditionalTimeItemsProps) => {
  const { servicePricings } = useServicePricings()

  const products: CollectionItem[] = sortBy("sort_product_order")(
    servicePricings.filter((item) => item.type === "additional_time")
  ) as unknown as CollectionItem[]

  const options = products.map((product: CollectionItem) => ({
    label: getLabel(product, isCommercialClientType),
    value: product.record_id
  }))

  const onChange = (event: SelectChangeEvent) => {
    const product = products.find(
      (item) => item.record_id === event.target.value
    )

    if (!product) {
      return
    }

    onAddCollectionItem(product)
  }

  return (
    <Wrapper>
      <Dropdown
        label="Select Additional Time"
        onChange={onChange}
        options={options}
        value=""
      />
    </Wrapper>
  )
}

export default AdditionalTimeItems
