import { recordId } from "./type"

const initialState = {
  id: ""
}

export const RecordIdReducer: any = (
  state = initialState,
  action: { type: string | null; data: any }
) => {
  if (action.type === recordId) {
    return {
      ...state,
      id: action.data
    }
  }
  return state
}
