import { Item, Title } from "./styled"

interface SurgeItemProps {
  label: string
  value?: number | string
}

const SurgeItem = ({ label, value }: SurgeItemProps) => (
  <Item>
    <Title>{`${label}:`}</Title>
    <span>{value}</span>
  </Item>
)

export default SurgeItem
