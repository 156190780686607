import { description } from "./type"

const initialState = {
  description: ""
}

export const DescriptionReducer: any = (
  state = initialState,
  action: { type: string | null; data: any }
) => {
  if (action.type === description) {
    return {
      ...state,
      description: action.data
    }
  }
  return state
}
