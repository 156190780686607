import styled from "@emotion/styled"
import { colors } from "@litta/ui"

export const Wrapper = styled.div`
  background: ${colors.white};
  border-radius: 20px;
  box-shadow: 0 3px 20px hsl(0deg 0% 84% / 35%);
  display: grid;
  margin-bottom: 20px;
  padding: 20px;
  row-gap: 20px;
  width: 100%;
`

export const Dropdowns = styled.div`
  column-gap: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
`
