import styled from "@emotion/styled"
import { Messages } from "@litta/ui"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  text-align: left;

  input {
    border: none;
    color: #747c84;
    height: 40px;
    margin: auto;
    margin-right: 10px;
    outline: none;
    padding: 8px 25px;
    font-size: 16px;
    box-shadow: 0 6px 20px rgb(214 214 214 / 75%);
    box-sizing: border-box;
    border-radius: 50px;
    background: white;
    font-family: "Poppins", sans-serif;
    width: 200px;
  }
`

export const Row = styled.div`
  align-items: center;
  display: flex;
`

export const ErrorMessage = styled(Messages.Error)`
  margin-left: 10px;
  margin-top: 10px;
`
