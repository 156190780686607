import { Wrapper } from "./styled"
import { useFailReasons } from "@litta/hooks"
import { Dropdown } from "@litta/ui"
import { SelectChangeEvent } from "@mui/material/Select"
import quoteState, { QuoteState } from "atoms/quote"
import { useRecoilState } from "recoil"

const ConfirmFail = () => {
  const [quote, setQuote] = useRecoilState<QuoteState>(quoteState)

  const { failReasons } = useFailReasons({ platform: "quotebuilder" })

  const onChangeFailReason = (event: SelectChangeEvent) => {
    if (event.target.value === "") {
      setQuote({
        ...quote,
        record: {
          ...quote.record,
          fail_reason: undefined,
          status: undefined
        }
      })
      return
    }

    setQuote({
      ...quote,
      record: {
        ...quote.record,
        fail_reason: event.target.value,
        status: "confirmed failed"
      }
    })
  }

  const options = [
    { label: " ", value: "" },
    ...failReasons.map(({ failed_reason }) => ({
      label: failed_reason,
      value: failed_reason
    }))
  ]

  return (
    <Wrapper>
      <Dropdown
        label="Fail Reason"
        onChange={onChangeFailReason}
        options={options}
        value={quote.record.fail_reason ?? ""}
      />
    </Wrapper>
  )
}

export default ConfirmFail
