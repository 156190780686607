import { Wrapper } from "./styled"
import LoadQuote from "./LoadQuote"
import SurgeItems from "./SurgeItems"

const Left = () => {
  return (
    <Wrapper>
      <LoadQuote />
      <SurgeItems />
    </Wrapper>
  )
}

export default Left
