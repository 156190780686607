import { addItem, addItems, removeItem } from "./type"

export const addCollectionItem: any = (update: any) => {
  return {
    type: addItem,
    data: update
  }
}

export const removeCollectionItem: any = (update: any) => {
  return {
    type: removeItem,
    data: update
  }
}

export const addMultiple: any = (update: any) => {
  return {
    type: addItems,
    data: update
  }
}
