import styled from "@emotion/styled"
import { colors } from "@litta/ui"

export const Wrapper = styled.div`
  align-self: flex-start;
  display: grid;
  row-gap: 20px;

  .address {
    margin-bottom: 0;
    width: 100%;
  }

  .expander {
    max-width: none;
  }
`

export const ContactDetailsBottom = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: -10px;
`

export const Middle = styled.div`
  align-items: flex-start;
  column-gap: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
`

export const MiddleLeft = styled.div`
  display: grid;
  row-gap: 20px;
`

export const LeadTimeQuestions = styled.div`
  background: ${colors.white};
  padding: 20px;
  border-radius: 20px;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 16px;
  display: grid;
  row-gap: 10px;
`

export const MiddleRight = styled.div`
  display: grid;
  row-gap: 20px;
`
