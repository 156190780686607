import styled from "@emotion/styled"

export const Item = styled.div`
  align-items: left;
  display: flex;
  justify-content: left;
`

export const Title = styled.div`
  font-weight: bold;
  padding-right: 10px;
`
