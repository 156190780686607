import styled from "@emotion/styled"

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 10px;
`

const QuoteButtons = styled.div`
  column-gap: 5px;
  display: grid;
`

export const QuoteButtonsTop = styled(QuoteButtons)`
  grid-template-columns: 1fr 1fr 1fr;
`

export const QuoteButtonsBottom = styled(QuoteButtons)`
  grid-template-columns: 1fr 1fr;
`
