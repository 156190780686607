import { useSelector } from "react-redux"

import "./main.scss"
import { CollectionItem, PostCodesSurge } from "@litta/types"
import { useItemGroups, usePostCodesSurge, useQuestions } from "@litta/hooks"

import { DismantleItem, ItemsCard } from "@litta/ui"

const DismantleItems = ({
  additionalItems,
  onAddCollectionItem,
  onRemoveCollectionItem,
  setPostCodeSurge
}) => {
  const itemGroups = useItemGroups()
  const { questions } = useQuestions({ platform: "quotebuilder" })
  const selectedAddress = useSelector(
    (state: any) => state.AddressReducer.address
  )
  const onUpdatePostCodesSurge = (response: PostCodesSurge | null) => {
    setPostCodeSurge(response)
  }
  usePostCodesSurge({ onUpdatePostCodesSurge, selectedAddress })

  const collectionItems = useSelector(
    (state: any) => state.CollectionItemsReducer.items
  )

  const addDismantle = (item: CollectionItem) => {
    const service = {
      type: "dismantle",
      record_id: item.record_id,
      dismantling_fee: item.dismantling_fee ? item.dismantling_fee : 0,
      litta_service_linked: `dismantle ${item.litta_service_linked}`,
      litta_parent_item: item.litta_service_linked
    }
    onAddCollectionItem(service)
  }
  const removeDismantle = (item: CollectionItem) => {
    const service = {
      type: "dismantle",
      dismantling_fee: item.dismantling_fee,
      litta_service_linked: `dismantle ${item.litta_service_linked}`
    }
    onRemoveCollectionItem(service)
  }
  const addDisconnect = (item: CollectionItem) => {
    const service = {
      type: "disconnect",
      record_id: item.record_id,
      disconnection_fee: item.disconnection_fee ? item.disconnection_fee : 0,
      litta_service_linked: `disconnect ${item.litta_service_linked}`,
      litta_parent_item: item.litta_service_linked
    }
    onAddCollectionItem(service)
  }
  const removeDisconnect = (item: CollectionItem) => {
    const service = {
      type: "disconnect",
      disconnection_fee: item.disconnection_fee,
      litta_service_linked: `disconnect ${item.litta_service_linked}`
    }
    onRemoveCollectionItem(service)
  }

  return (
    <div className="dismantle-items">
      {collectionItems.filter((item: CollectionItem) => item.dismantling_prompt)
        .length > 0 && (
        <div className="dismantle-item expander">
          <DismantleItem
            additionalItems={collectionItems.filter(
              (item: CollectionItem) => item.dismantling_prompt
            )}
            items={collectionItems}
            question={
              questions.find(
                (question) => question.field_name === "dismantling_items"
              )?.question
            }
            addItem={(item: CollectionItem) => addDismantle(item)}
            removeItem={(item: CollectionItem) => removeDismantle(item)}
            type="dismantle"
            icon="dismantling.png"
          />
        </div>
      )}
      {collectionItems.filter(
        (item: CollectionItem) => item.disconnecting_prompt
      ).length > 0 && (
        <div className="dismantle-item expander">
          <DismantleItem
            additionalItems={collectionItems.filter(
              (item: CollectionItem) => item.disconnecting_prompt
            )}
            items={collectionItems}
            question={
              questions.find(
                (question) => question.field_name === "disconnection_items"
              )?.question
            }
            addItem={(item: CollectionItem) => addDisconnect(item)}
            removeItem={(item: CollectionItem) => removeDisconnect(item)}
            type="disconnect"
            icon="disconnecting.png"
          />
        </div>
      )}
      {collectionItems.filter(
        (item: CollectionItem) => item.additional_item_prompt
      ).length > 0 && (
        <div>
          <ItemsCard
            error={false}
            title={
              questions.find(
                (question) => question.field_name === "haz_items_included"
              )?.question ?? ""
            }
            collectionItems={collectionItems}
            additionalItems={additionalItems}
            loading={itemGroups.isLoading}
            addCollectionItem={onAddCollectionItem}
            removeCollectionItem={onRemoveCollectionItem}
            groups={itemGroups.data}
            isGrouped
          />
        </div>
      )}
    </div>
  )
}

export default DismantleItems
