import { useMemo } from "react"
import { useRecoilValue } from "recoil"
import quoteState, { QuoteState } from "atoms/quote"
import { Wrapper } from "./styled"
import { usePostCodesSurge } from "@litta/hooks"
import SurgeItem from "./SurgeItem"
import { format } from "date-fns"

const SurgeItems = () => {
  const quote = useRecoilValue<QuoteState>(quoteState)
  const { postCodesSurge } = usePostCodesSurge({})

  const createdDateTime = useMemo(() => {
    if (quote.record.timestamp?._seconds) {
      return format(
        new Date(quote.record.timestamp._seconds * 1000),
        "MMM d, y @ H:mm"
      )
    }

    return null
  }, [quote.record?.timestamp])

  const insideM25 = useMemo(
    () => (postCodesSurge?.within_m25 ? "Yes" : "No"),
    [postCodesSurge?.within_m25]
  )

  return (
    <Wrapper>
      {quote.record.quote_ref && (
        <SurgeItem label="Ref" value={quote.record.quote_ref} />
      )}
      {quote.record.tracked_customer?.length && (
        <SurgeItem
          label="Commercial"
          value={quote.record.tracked_customer[0]}
        />
      )}
      <SurgeItem label="B2B/B2C" value={quote.record.B2B ? "B2B" : "B2C"} />
      {quote.record.referral_indicator && (
        <SurgeItem label="Referral" value={quote.record.referral_indicator} />
      )}
      <SurgeItem label="Status" value={quote.record.action_status} />
      {createdDateTime && <SurgeItem label="Created" value={createdDateTime} />}
      <SurgeItem label="Multiple" value={postCodesSurge?.surge_multiplier} />
      <SurgeItem label="Lead Time" value={postCodesSurge?.lead_time_days} />
      <SurgeItem label="Inside M25" value={insideM25} />
    </Wrapper>
  )
}

export default SurgeItems
