import { addCoupon } from "./type"

const initialState = {}

export const CouponsReducer: any = (
  state = initialState,
  action: { type: string | null; data: any }
) => {
  if (action.type === addCoupon) {
    return action.data
  }
  return state
}
