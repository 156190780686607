import { combineReducers } from "redux"
import { ShowSlotsReducer } from "./reduxEmpire/showSlots/reducer"
import { AddressReducer } from "./reduxEmpire/address/reducer"
import { QuoteIdReducer } from "./reduxEmpire/quoteId/reducer"
import { AcceptedTermsReducer } from "./reduxEmpire/acceptTerms/reducer"
import { CollectionItemsReducer } from "./reduxEmpire/collectionItems/reducer"
import { CategoryReducer } from "./reduxEmpire/category/reducer"
import { AnswersReducer } from "./reduxEmpire/answers/reducer"
import { RemovalTypeReducer } from "./reduxEmpire/removalType/reducer"
import { ImagesReducer } from "./reduxEmpire/images/reducer"
import { DescriptionReducer } from "./reduxEmpire/description/reducer"
import { RecordIdReducer } from "./reduxEmpire/recordId/reducer"
import { LeadTimeReducer } from "./reduxEmpire/leadTime/reducer"
import { ReferenceReducer } from "./reduxEmpire/reference/reducer"
import { CouponsReducer } from "./reduxEmpire/coupons/reducer"

export const rootedReducer: any = combineReducers({
  AcceptedTermsReducer,
  AddressReducer,
  AnswersReducer,
  CollectionItemsReducer,
  CategoryReducer,
  CouponsReducer,
  DescriptionReducer,
  ImagesReducer,
  LeadTimeReducer,
  RecordIdReducer,
  ReferenceReducer,
  RemovalTypeReducer,
  QuoteIdReducer,
  ShowSlotsReducer
})
