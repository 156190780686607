import { getDocs, collection, query, where } from "firebase/firestore/lite"
import {
  ref,
  uploadBytes,
  getDownloadURL,
  StorageReference,
  deleteObject,
  getStorage
} from "firebase/storage"
import { storage } from "firebase"
import db from "@litta/firebase"
import { CollectionItem } from "@litta/types"

export const getAdditionalItems = async () => {
  const servicePricingRef = collection(db, "service_pricing")
  const q = query(servicePricingRef, where("additional_item", "==", true))
  const productSnapshot = await getDocs(q)

  const productList = productSnapshot.docs.map(
    (doc) => doc.data() as CollectionItem
  )
  return productList
}

const upload = async (storageRef: StorageReference, blob: Blob) => {
  const result = await uploadBytes(storageRef, blob)

  return result
}

const getURL = async (ref: any) => {
  const result = await getDownloadURL(ref)

  return result
}

export const deleteImage = async (url: string) => {
  const storage = getStorage()

  // Create a reference to the file to delete
  const ImageRef = ref(storage, url)

  // Delete the file
  deleteObject(ImageRef)
    .then(() => {
      // eslint-disable-next-line no-console
      console.log("deleted file")
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log(error)
      // Uh-oh, an error occurred!
    })
}

export const uploadFromBlobAsync = async (
  blobUrl: any,
  name: any,
  type?: any
) => {
  if (!blobUrl || !name) return null

  try {
    const storageRef = ref(storage, name)
    const blob = await fetch(blobUrl).then((r) => r.blob())

    const snapshot = await upload(storageRef, blob)

    const url = await getURL(snapshot.ref)

    return {
      url,
      type
    }
  } catch (error) {
    throw error
  }
}
