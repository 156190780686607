import styled from "@emotion/styled"

export const Wrapper = styled.div`
  align-items: flex-end;
  display: grid;
  justify-items: flex-end;
  row-gap: 5px;

  & h2 {
    font-size: 16px;
  }
`

export const Items = styled.div`
  & h3 {
    font-size: 14px;
    font-weight: 400;
  }
`
