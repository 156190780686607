import {
  useContext,
  createContext,
  useEffect,
  useState,
  Dispatch,
  SetStateAction
} from "react"
import { auth } from "@litta/firebase"
import { isEmpty } from "lodash"
import {
  GoogleAuthProvider,
  signOut,
  onAuthStateChanged,
  signInWithPopup
} from "firebase/auth"

type UserType = {
  user: {}
  email: string
  mobilenumber: string
  displayName: string
}

export type AuthContextType = {
  user: UserType | null
  googleSignIn: () => void
  logOut: () => void
  loadingAuth: boolean
  setLoadingAuth: Dispatch<SetStateAction<boolean>>
}

const AuthContext = createContext<AuthContextType | null>(null)

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState<UserType | null>(null)
  const [loadingAuth, setLoadingAuth] = useState<boolean>(true)

  const googleSignIn = async () => {
    try {
      signInWithPopup(auth, new GoogleAuthProvider())
    } catch (err) {
      console.log("err", err)
    }
  }

  const logOut = () => {
    signOut(auth)
    setUser(null)
  }

  const checkDomain = (user) => {
    if (!user || isEmpty(user.email)) return null
    const domainExtension = user?.email.indexOf("@")
    const domain = user?.email.slice(domainExtension + 1, user?.email.length)
    return domain
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser: any) => {
      const isEmptyUser = isEmpty(currentUser)
      const userDomain = checkDomain(currentUser)

      if (!isEmptyUser && userDomain === "litta.co") {
        setUser(currentUser)
      }
      setLoadingAuth(false)
    })

    return () => {
      unsubscribe()
    }
  }, [])

  return (
    <AuthContext.Provider
      value={{ googleSignIn, logOut, user, loadingAuth, setLoadingAuth }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const UserAuth = (): AuthContextType | null => {
  return useContext(AuthContext)
}
