import { ChangeEvent, useCallback, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import equals from "lodash/fp/equals"
import { CollectionItem } from "@litta/types"

import { AddressDetails, PostCodesSurge } from "@litta/types"
import { useEmailVerify, usePostCodesSurge, useQuestions } from "@litta/hooks"
import { useRecoilState } from "recoil"
import quoteState, { DEFAULT_ADDRESS_DETAILS, QuoteState } from "atoms/quote"

import {
  ContactDetails,
  ItemSearch,
  LocationDetailsCard,
  MediaUpload,
  Textarea,
  RoundedBorder
} from "@litta/ui"

import { bulkUpdateImages } from "reduxEmpire/images/action"
import { uploadFromBlobAsync, deleteImage } from "../actions"
import {
  ContactDetailsBottom,
  Middle,
  MiddleLeft,
  MiddleRight,
  Wrapper
} from "./styled"

import DismantleItems from "./DismantleItems"
import RubbishItems from "./RubbishItems"
import AdditionalTimeItems from "./AdditionalTimeItems"

interface LeftProps {
  additionalItems: any
  onAddCollectionItem: (item: CollectionItem) => void
  onRemoveCollectionItem: (item: CollectionItem) => void
  onSetAddress: any
  setPostCodeSurge: any
}

const Left = ({
  additionalItems,
  onAddCollectionItem,
  onRemoveCollectionItem,
  onSetAddress,
  setPostCodeSurge
}: LeftProps) => {
  const [quote, setQuote] = useRecoilState<QuoteState>(quoteState)

  const { questions } = useQuestions({
    platform: "quotebuilder"
  })

  const { isValid: isEmailValid } = useEmailVerify({
    email: quote.record.userDetails.email,
    source: "quotebuilder"
  })

  const selectedAddress = useSelector(
    (state: any) => state.AddressReducer.address
  )
  const onUpdatePostCodesSurge = (response: PostCodesSurge | null) => {
    setPostCodeSurge(response)
  }
  usePostCodesSurge({ onUpdatePostCodesSurge, selectedAddress })

  const collectionItems = useSelector(
    (state: any) => state.CollectionItemsReducer.items
  )

  const onUpdateEmail = (email: string) => {
    setQuote((presentQuote) => ({
      ...presentQuote,
      record: {
        ...presentQuote.record,
        userDetails: {
          ...presentQuote.record.userDetails,
          email
        }
      }
    }))
  }

  const onUpdateNumber = (mobilenumber: string) => {
    setQuote((presentQuote) => ({
      ...presentQuote,
      record: {
        ...presentQuote.record,
        userDetails: {
          ...presentQuote.record.userDetails,
          mobilenumber
        }
      }
    }))
  }

  const onUpdateName = (name: string) => {
    setQuote((presentQuote) => ({
      ...presentQuote,
      record: {
        ...presentQuote.record,
        userDetails: {
          ...presentQuote.record.userDetails,
          name
        }
      }
    }))
  }

  const dispatch = useDispatch()

  const onBulkUpdateImages = (images: any) => {
    dispatch(bulkUpdateImages(images))
    setQuote((quote) => ({
      ...quote,
      record: {
        ...quote.record,
        images: images.map((i) => i.url)
      }
    }))
  }

  const onSetDescription = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setQuote({
      ...quote,
      record: {
        ...quote.record,
        description: event.currentTarget.value
      }
    })
  }

  const onSetNotes = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setQuote({
      ...quote,
      record: {
        ...quote.record,
        notes_to_customer: event.currentTarget.value
      }
    })
  }

  const images = useSelector((state: any) => state.ImagesReducer.images)

  const defaultEnteredPostcode = useMemo(
    () => quote.record?.address_details?.postcode,
    [quote.record]
  )

  const {
    record: { address_details: addressDetails }
  } = quote
  const onAddressDetailsChange = useCallback(
    (newAddressDetails: AddressDetails) => {
      if (!equals(addressDetails)(newAddressDetails)) {
        const newFormattedAddress = [
          ...(newAddressDetails?.formatted_address ?? []),
          newAddressDetails.postcode
        ]
          ?.filter((item) => Boolean(item))
          .join(", ")

        setQuote({
          ...quote,
          record: {
            ...quote.record,
            address_details: newAddressDetails
          }
        })

        onSetAddress(newFormattedAddress)
      }
    },
    [addressDetails, quote, setQuote]
  )

  const onResetAddressDetails = () => {
    setQuote({
      ...quote,
      record: {
        ...quote.record,
        address_details: DEFAULT_ADDRESS_DETAILS
      }
    })
  }

  const isCommercialClientType = quote?.record?.client_type === "commercial"

  return (
    <Wrapper>
      <Middle>
        <MiddleLeft>
          <LocationDetailsCard
            defaultEnteredPostcode={defaultEnteredPostcode}
            defaultSelectedAddress={{
              ...addressDetails,
              building_name: addressDetails?.building_name ?? "",
              building_number: addressDetails?.building_number ?? "",
              country: addressDetails?.country ?? "",
              district: addressDetails?.district ?? "",
              formatted_address: addressDetails?.formatted_address ?? [],
              line_4: addressDetails?.line_4 ?? "",
              locality: addressDetails?.locality ?? "",
              sub_building_name: addressDetails?.sub_building_name ?? "",
              sub_building_number: addressDetails?.sub_building_number ?? "",
              thoroughfare: addressDetails?.locality ?? ""
            }}
            isDisabled={false}
            isQuoteBuilder
            onFormChange={onAddressDetailsChange}
            onResetAddress={onResetAddressDetails}
          />

          <ContactDetails
            isEmailValid={isEmailValid}
            isVertical
            onUpdateEmail={onUpdateEmail}
            onUpdateNumber={onUpdateNumber}
            onUpdateName={onUpdateName}
            questions={questions.filter(
              (question) => question.page === "contact_details"
            )}
            userDetails={quote.record.userDetails}
          />

          <ContactDetailsBottom>
            {`Calls Attempted: ${quote.record.call_attempt_count ?? "0"}`}
          </ContactDetailsBottom>
          <RoundedBorder>
            <Textarea
              label={
                questions.find((question) => question.field_name === "items")
                  ?.question
              }
              onChange={onSetDescription}
              rows={images.length > 4 ? 32 : images.length > 0 ? 23 : 14}
              value={quote.record.description}
            />
          </RoundedBorder>
          <MediaUpload
            deleteImage={deleteImage}
            images={images}
            onBulkUpdateImages={onBulkUpdateImages}
            uploadFromBlobAsync={uploadFromBlobAsync}
          />
        </MiddleLeft>

        <MiddleRight>
          <RoundedBorder>
            <Textarea
              label={
                questions.find(
                  (question) => question.field_name === "notes_to_customer"
                )?.question
              }
              onChange={onSetNotes}
              value={quote.record.notes_to_customer}
            />
          </RoundedBorder>
          <AdditionalTimeItems
            isCommercialClientType={isCommercialClientType}
            onAddCollectionItem={onAddCollectionItem}
          />

          <RubbishItems
            onAddCollectionItem={onAddCollectionItem}
            onRemoveCollectionItem={onRemoveCollectionItem}
          />

          {!quote.record.tracked_customer && (
            <ItemSearch
              addCollectionItem={onAddCollectionItem}
              isQuoteBuilder
              items={collectionItems}
            />
          )}
          {!isCommercialClientType && (
            <DismantleItems
              additionalItems={additionalItems}
              onAddCollectionItem={onAddCollectionItem}
              onRemoveCollectionItem={onRemoveCollectionItem}
              setPostCodeSurge={setPostCodeSurge}
            />
          )}
        </MiddleRight>
      </Middle>
    </Wrapper>
  )
}

export default Left
