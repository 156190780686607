import styled from "@emotion/styled"
import Typography from "@mui/material/Typography"

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: flex-end;
  row-gap: 2px;
`

export const Icon = styled.div`
  font-size: 26px;
`

export const Text = styled(Typography, {
  shouldForwardProp: (prop) => prop !== "positive"
})<{ positive: boolean }>`
  color: ${({ positive }) => (positive ? "green" : "red")};
  font-size: 16px;
  font-weight: 700;
`
