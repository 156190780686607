import { useMemo } from "react"

import { Icon, Text, Wrapper } from "./styled"
import { useRecoilValue } from "recoil"
import quoteState, { QuoteState } from "atoms/quote"

const EstimatedProfit = ({ recommendedPartnerPayout }) => {
  const quote = useRecoilValue<QuoteState>(quoteState)

  const estimatedProfit = useMemo(
    () =>
      quote.totalPriceAdjusted -
      quote.totalPriceAdjusted / 5 -
      recommendedPartnerPayout,
    [recommendedPartnerPayout, quote.totalPriceAdjusted]
  )

  const profitMargin = useMemo(
    () => (estimatedProfit / quote.totalPriceAdjusted) * 100,
    [estimatedProfit, quote.totalPriceAdjusted]
  )

  return (
    <Wrapper>
      <Icon>{estimatedProfit >= 0 ? "🤑" : "🤬"}</Icon>
      <Text
        positive={estimatedProfit >= 0}
        variant="h2"
      >{`Estimated Profit: £${(isNaN(estimatedProfit)
        ? 0
        : estimatedProfit
      ).toFixed(2)}`}</Text>
      <Text positive={estimatedProfit >= 0} variant="h2">{`Margin: ${(isNaN(
        profitMargin
      )
        ? 0
        : profitMargin
      ).toFixed(2)}%`}</Text>
    </Wrapper>
  )
}

export default EstimatedProfit
