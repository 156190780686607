import { addImages, bulkUpdate } from "./type"

export const addQuoteImages: any = (update: any) => {
  return {
    type: addImages,
    data: update
  }
}

export const bulkUpdateImages: any = (update: any) => {
  return {
    type: bulkUpdate,
    data: update
  }
}
