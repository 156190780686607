import { addImages, bulkUpdate } from "./type"

const initialState = {
  images: [] as any
}

export const ImagesReducer: any = (
  state = initialState,
  action: { type: string | null; data: any }
) => {
  if (action.type === addImages) {
    return {
      ...state,
      images: [...state.images].concat(action.data)
    }
  } else if (action.type === bulkUpdate) {
    return {
      ...state,
      images: action.data
    }
  }
  return state
}
