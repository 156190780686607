// Libraries
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import Protected from "ProtectedRoute"
import { AuthContextProvider } from "AuthContext"
// Pages
import Main from "pages/Main"
import Signin from "pages/SignIn"

export default function AppRouter() {
  return process.env.REACT_APP_ENV !== "development" ? (
    <Router>
      <AuthContextProvider>
        <Routes>
          <Route path="/signin" element={<Signin />} />
          <Route
            path="/"
            element={
              <Protected>
                <Main />
              </Protected>
            }
          />
        </Routes>
      </AuthContextProvider>
    </Router>
  ) : (
    <Router>
      <Routes>
        <Route path="/" element={<Main />} />
      </Routes>
    </Router>
  )
}
