import Left from "./Left"
import Right from "./Right"
import Middle from "./Middle"
import { Wrapper } from "./styled"

interface HeaderTopProps {
  bookingType: any
  isTouched: boolean
  manualAdjustment: number
  plusMinus: boolean
  salesOwner: any
  setBookingType: any
  setIsTouched: (isTouched: boolean) => void
  setManualAdjustment: (manualAdjustment: number) => void
  setPlusMinus: (plusMinus: boolean) => void
  setSalesOwner: any
}

const HeaderTop = ({
  bookingType,
  isTouched,
  manualAdjustment,
  plusMinus,
  salesOwner,
  setBookingType,
  setIsTouched,
  setManualAdjustment,
  setPlusMinus,
  setSalesOwner
}: HeaderTopProps) => (
  <Wrapper>
    <Left />
    <Middle
      bookingType={bookingType}
      salesOwner={salesOwner}
      setBookingType={setBookingType}
      setSalesOwner={setSalesOwner}
    />
    <Right
      isTouched={isTouched}
      manualAdjustment={manualAdjustment}
      plusMinus={plusMinus}
      setIsTouched={setIsTouched}
      setManualAdjustment={setManualAdjustment}
      setPlusMinus={setPlusMinus}
    />
  </Wrapper>
)

export default HeaderTop
