import { useEffect, useMemo, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import Header from "components/HeaderSide/Header"
import { getFunctions, httpsCallable } from "firebase/functions"
import { UserAuth, AuthContextType } from "AuthContext"
import { useNavigate } from "react-router-dom"
// import isBefore from "date-fns/isBefore"
import moment from "moment"
import { useSearchParams } from "react-router-dom"
import {
  addCollectionItem,
  addMultiple,
  removeCollectionItem
} from "reduxEmpire/collectionItems/action"
import { setAnswers } from "reduxEmpire/answers/action"
import "./main.scss"
import { getAdditionalItems } from "./actions"
import { CollectionItem, PostCodesSurge } from "@litta/types"
import {
  useEnquiryOptions,
  usePostCodesSurge,
  usePromoCode,
  useQuestions,
  useSelectedDayAndTime,
  useNextAvailableDate
} from "@litta/hooks"

import { updateAddress } from "reduxEmpire/address/action"
import { updateLeadTime } from "reduxEmpire/leadTime/action"
import { useRecoilState, useRecoilValue } from "recoil"
import quoteState, {
  TotalQuotePriceState,
  QuoteState,
  isQuoteValidState
} from "atoms/quote"

import HeaderTop from "./HeaderTop"
import Left from "./Left"
import {
  useCalculateTotal,
  useCalculateTotalWithCoupons,
  useCallOutFee,
  useMultipleItemPricing
} from "@litta/hooks"
import Right from "./Right"
import omit from "lodash/fp/omit"
import { bulkUpdateImages } from "reduxEmpire/images/action"
import {
  ClientTypeBar,
  CustomerType as CustomerTypeWrapper,
  MainWrapper,
  Wrapper,
  InputSpacer,
  LogOutContainer,
  LogOutWrapper,
  LogOut,
  QuoteBuilderTitle
} from "./styled"
import { Input, Radio } from "@litta/ui"
import useUpdateUserHistory from "hooks/useUpdateUserHistory"
const READ_ONLY_QUOTE_PROPERTIES = [
  "B2B",
  "action_status",
  "call_attempt_count",
  "lowest_acceptable_amount",
  "total"
]
const Main = () => {
  const UserControl = UserAuth() as AuthContextType
  const navigate = useNavigate()
  const [additionalItems, setAdditionalItems] = useState<CollectionItem[]>([])
  const [manualAdjustment, setManualAdjustment] = useState<number>(0)
  const [isManualAdjustmentTouched, setIsManualAdjustmentTouched] =
    useState<boolean>(false)
  const [plusMinus, setPlusMinus] = useState<boolean>(false)
  const [postCodeSurge, setPostCodeSurge] = useState<PostCodesSurge | null>(
    null
  )
  const [customNextDate, setCustomNextDate] = useState<string>("")
  const { nextAvailableDate } = useNextAvailableDate({
    postcode: postCodeSurge?.outer_postcode
  })
  const [searchParams] = useSearchParams()
  const [quote, setQuote] = useRecoilState<QuoteState>(quoteState)
  const [totalQuotePrice, setTotalQuotePrice] =
    useRecoilState<number>(TotalQuotePriceState)
  const [salesOwner, setSalesOwner] = useState(null)
  const [bookingType, setBookingType] = useState(null)
  const [recordIdLoading, setRecordIdLoading] = useState<boolean>(false)
  const { questions } = useQuestions({ platform: "quotebuilder" })
  const { promoCode } = usePromoCode({})
  const customerTypeOptions = useEnquiryOptions()
  const { quoteErrors } = useRecoilValue(isQuoteValidState)
  const {
    selectedDayAndTime,
    setSelectedDay,
    setSelectedLastBookingTime,
    setSelectedTimeslot,
    setSelectedTimeSlotId,
    setSelectedTimeslotFee
  } = useSelectedDayAndTime()
  const collectionItems = useSelector(
    (state: any) => state.CollectionItemsReducer.items
  )
  const { callOutFee } = useCallOutFee({ collectionItems })
  const { data: enquiryOptions } = useEnquiryOptions()
  const percentageIncrease = enquiryOptions?.find(
    (item) => item.slug === quote.record.enquiry_type
  )?.percent_increase
  const recordId = searchParams.get("id")
  const dispatch = useDispatch()
  const updateUserHistory = useUpdateUserHistory()
  const onSetAddress = (formattedAddress: string) => {
    dispatch(updateAddress(formattedAddress))
  }
  const onAddCollectionItem = (item: CollectionItem) => {
    dispatch(addCollectionItem(item))
  }
  const onAddCollectionItems = (items: CollectionItem[]) => {
    dispatch(addMultiple(items))
  }
  const onRemoveCollectionItem = (item: CollectionItem) => {
    dispatch(removeCollectionItem(item))
  }
  const onSetAnswers = (newAnswersData: any) => {
    dispatch(setAnswers(newAnswersData))
  }
  const onUpdateLeadTime = (value: any) => {
    dispatch(updateLeadTime(value))
  }
  const onBulkUpdateImages = (images: any) => {
    dispatch(bulkUpdateImages(images))
  }
  useEffect(() => {
    setRecordIdLoading(true)
    const functions = getFunctions()
    const getRecordById = httpsCallable(functions, "getRecordById")
    if (recordId) {
      getRecordById({ recordId }).then((result: any) => {
        setRecordIdLoading(false)
        if (result.data.data) {
          const { data } = result.data
          setQuote({
            ...quote,
            record: {
              ...data,
              userDetails: {
                email: data.userDetails.email ?? "",
                mobilenumber: data.userDetails.mobilenumber ?? "",
                name: data.userDetails.name ?? ""
              }
            },
            quoteType: "update"
          })
          updateUserHistory(data.userDetails.email)
          onSetAddress(data.address)
          onAddCollectionItems([
            ...data.items,
            ...data.dismantle,
            ...data.disconnect
          ])
          onSetAnswers(data.answers)
          onUpdateLeadTime(data.leadTime)
          onBulkUpdateImages(
            data.images.map((image: string) => ({ url: image }))
          )
          setBookingType(data.booking_type)
          setSalesOwner(data.owner)
          // Only set time slot if it is hasn't expired
          // if (
          //   !isBefore(
          //     new Date(new Date().setHours(0, 0, 0)),
          //     new Date(data.selectedDate.split("-").reverse("-").join("-"))
          //   )
          // ) {
          // remember before it had this condition above
          if (data.selectedDate) {
            setSelectedDay(data.selectedDate)
            setSelectedTimeslotFee(data.TIME_SLOT_FEE)
            setSelectedLastBookingTime(data.selectedLastBookingTime)
            setSelectedTimeslot(data.selectedSlot)
            setSelectedTimeSlotId(data.selected_slot_id)
          }
          if (data.selected_slot_id !== "custom") {
            setSelectedLastBookingTime(null)
          }
          // }
          if (data.manual_adjustment) {
            setManualAdjustment(
              data.manual_adjustment >= 0
                ? data.manual_adjustment
                : data.manual_adjustment * -1
            )
            setPlusMinus(data.manual_adjustment >= 0)
          }
        }
      })
    }
  }, [recordId])
  const checkNextDate = (nextAvailableDate, todayDate) => {
    const nextDate = moment(nextAvailableDate, "DD-MM-YYYY")
    const isBeforeNextAvailableDate = nextDate.isBefore(todayDate)
    return isBeforeNextAvailableDate
  }
  useEffect(() => {
    const todayDate = moment().format("DD-MM-YYYY")
    if (nextAvailableDate && !quote.record.selectedDate) {
      if (checkNextDate(nextAvailableDate, todayDate)) {
        setCustomNextDate(todayDate)
        setSelectedDay(todayDate)
        return
      }
      setSelectedDay(nextAvailableDate)
    }
    if (quote.record.selectedDate) {
      const yesterday = moment().subtract(1, "days")
      const targetDate = moment(quote.record.selectedDate, "DD-MM-YYYY")
      const isAfter = targetDate.isAfter(yesterday)
      if (isAfter && !checkNextDate(nextAvailableDate, todayDate)) {
        setCustomNextDate(todayDate)
        setSelectedDay(todayDate)
        return
      }
    }
    setCustomNextDate(nextAvailableDate)
    setSelectedDay(nextAvailableDate)
  }, [nextAvailableDate])
  useEffect(() => {
    const getData = async () => {
      const data = await getAdditionalItems()
      setAdditionalItems(data)
    }
    getData()
  }, [])
  const selectedAddress = useSelector(
    (state: any) => state.AddressReducer.address
  )
  const onUpdatePostCodesSurge = (response: PostCodesSurge | null) => {
    setPostCodeSurge(response)
  }
  usePostCodesSurge({ onUpdatePostCodesSurge, selectedAddress })
  const answers = useSelector((state: any) => state.AnswersReducer)
  const leadTime = useSelector((state: any) => state.LeadTimeReducer.leadTime)
  const images = useSelector((state: any) => state.ImagesReducer.images)
  const getItemObject = (item: CollectionItem) => ({
    ...item,
    id: item.id ?? item.record_id
  })
  const newQuote = useMemo(
    () => ({
      ...omit(READ_ONLY_QUOTE_PROPERTIES)(quote.record),
      address: selectedAddress,
      answers: Object.keys(answers).map((key) => answers[key]),
      booking_type: bookingType,
      coupon: promoCode?.code,
      disconnect: collectionItems
        .filter((item: CollectionItem) => item.type === "disconnect")
        .map(getItemObject),
      dismantle: collectionItems
        .filter((item: CollectionItem) => item.type === "dismantle")
        .map(getItemObject),
      images: images.map((image: any) => image.url),
      items: collectionItems
        .filter(
          (item: CollectionItem) =>
            item.type !== "dismantle" && item.type !== "disconnect"
        )
        .map(getItemObject),
      leadTime,
      manual_adjustment: plusMinus ? manualAdjustment : -manualAdjustment,
      owner: salesOwner,
      recordId,
      referral_indicator: quote.record.referral_indicator ?? "quotebuilder"
    }),
    [
      answers,
      bookingType,
      collectionItems,
      images,
      leadTime,
      manualAdjustment,
      plusMinus,
      quote,
      recordId,
      salesOwner,
      selectedAddress
    ]
  )
  const isBusiness = quote.record.enquiry_type !== "residential"

  const multipleItemPricing = useMultipleItemPricing({
    items: collectionItems,
    customerType: quote.record.enquiry_type
  })

  const total = useCalculateTotal({
    answers,
    callOutFee,
    isBusiness,
    items: collectionItems,
    multipleItemPricing,
    percentageIncrease,
    questions,
    ...(quote.record.client_type !== "commercial" && {
      surgeMultiplier: postCodeSurge?.surge_multiplier
    })
  })

  const { discount, total: totalWithFees } = useCalculateTotalWithCoupons({
    dayFee: selectedDayAndTime.dayFee,
    timeSlotFee: selectedDayAndTime.timeSlotFee,
    quoteAmount: totalQuotePrice,
    coupon: promoCode
  })

  useEffect(() => {
    const adjustment = isNaN(manualAdjustment) ? 0 : manualAdjustment
    setQuote({
      ...quote,
      discount,
      totalPrice: totalWithFees,
      totalPriceAdjusted: plusMinus
        ? totalWithFees + adjustment
        : totalWithFees - adjustment,
      totalPriceAdjustedWihoutFees: plusMinus
        ? total + adjustment
        : total - adjustment
    })
  }, [discount, manualAdjustment, plusMinus, setQuote, totalWithFees])

  useEffect(() => {
    const functions = getFunctions()
    const calculateTotal = httpsCallable(functions, "check_total_price")

    const adjustment = isNaN(manualAdjustment) ? 0 : manualAdjustment

    calculateTotal({
      ...newQuote,
      ...{
        selected_slot_id: selectedDayAndTime.timeSlotId,
        selectedSlot: selectedDayAndTime.timeSlot
      }
    }).then((res: any) => {
      const { data } = res
      const finalPrice = plusMinus
        ? data.total + adjustment
        : data.total - adjustment

      setTotalQuotePrice(finalPrice)
    })
  }, [selectedDayAndTime, total, manualAdjustment, plusMinus])

  useEffect(() => {
    setQuote({
      ...quote,
      discount,
      record: {
        ...quote.record,
        TIME_SLOT_FEE: selectedDayAndTime.timeSlotFee,
        selectedDate: selectedDayAndTime.day ?? "",
        selectedLastBookingTime: selectedDayAndTime.lastBookingTime,
        selectedSlot: selectedDayAndTime.timeSlot ?? "",
        selected_slot_id: selectedDayAndTime.timeSlotId ?? ""
      }
    })
  }, [selectedDayAndTime])

  useEffect(() => {
    if (process.env.NODE_ENV && UserControl) {
      setQuote((prevObj) => ({
        ...prevObj,
        record: {
          ...prevObj.record,
          quote_updated_by: UserControl.user?.email as string
        }
      }))
    }
  }, [])
  const onLogout = async () => {
    await UserControl.logOut()
    navigate("/signin")
  }

  return (
    <Wrapper>
      {quote.record.client_type && (
        <ClientTypeBar>{`${quote.record.client_type} - ${quote.record.tracked_customer?.[0]} - ${quote.record.relationship_manager}`}</ClientTypeBar>
      )}
      <div
        className={`top-quote-builder${
          quote.record.client_type ? " client-type" : ""
        }`}
      >
        <div className="header">
          <Header />
        </div>
        {UserControl && (
          <LogOutContainer>
            <LogOutWrapper>
              <LogOut>Logged in as:</LogOut>
              <LogOut cursor="pointer" marginLeft="5px" onClick={onLogout}>
                {UserControl.user?.displayName} (Logout)
              </LogOut>
            </LogOutWrapper>
            <QuoteBuilderTitle>{"Quote Builder"}</QuoteBuilderTitle>
          </LogOutContainer>
        )}
        {quote.record.client_type !== "commercial" && (
          <CustomerTypeWrapper>
            <Radio
              hasError={quoteErrors.enquiry_type}
              name="enquiry_type"
              onChange={(e) => {
                const { value } = e.target
                setQuote((presentQuote) => ({
                  ...presentQuote,
                  record: {
                    ...presentQuote.record,
                    enquiry_type:
                      value === "residential" || value === "business"
                        ? value
                        : ""
                  }
                }))
              }}
              options={
                customerTypeOptions.data?.map((eo) => ({
                  label: eo.option,
                  value: eo.slug
                })) || []
              }
              selectedValue={quote.record.enquiry_type}
            />
            {isBusiness ? (
              <Input
                type="text"
                value={quote.record.business_name}
                onChange={(value) => {
                  setQuote({
                    ...quote,
                    record: {
                      ...quote.record,
                      business_name: value
                    }
                  })
                }}
                placeholder="Business name"
              />
            ) : (
              <InputSpacer />
            )}
          </CustomerTypeWrapper>
        )}
      </div>
      <HeaderTop
        bookingType={bookingType}
        isTouched={isManualAdjustmentTouched}
        manualAdjustment={manualAdjustment}
        plusMinus={plusMinus}
        salesOwner={salesOwner}
        setBookingType={setBookingType}
        setIsTouched={setIsManualAdjustmentTouched}
        setManualAdjustment={setManualAdjustment}
        setPlusMinus={setPlusMinus}
        setSalesOwner={setSalesOwner}
      />
      <div className="main">
        <MainWrapper>
          <Left
            additionalItems={additionalItems}
            onAddCollectionItem={onAddCollectionItem}
            onRemoveCollectionItem={onRemoveCollectionItem}
            onSetAddress={onSetAddress}
            setPostCodeSurge={setPostCodeSurge}
          />
          <Right
            nextAvailableDate={customNextDate}
            manualAdjustment={manualAdjustment}
            newQuote={newQuote}
            onAddCollectionItem={onAddCollectionItem}
            onRemoveCollectionItem={onRemoveCollectionItem}
            plusMinus={plusMinus}
            postCodeSurge={postCodeSurge}
            recordIdLoading={recordIdLoading}
            setIsTouched={setIsManualAdjustmentTouched}
            setManualAdjustment={setManualAdjustment}
            setPlusMinus={setPlusMinus}
          />
        </MainWrapper>
      </div>
    </Wrapper>
  )
}
export default Main
