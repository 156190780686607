import { ChangeEvent } from "react"
import { useSearchParams } from "react-router-dom"

import { useRecoilState } from "recoil"
import quoteState, { QuoteState } from "atoms/quote"

import { DateTimePicker, Textarea } from "@litta/ui"
import { Dropdowns, Wrapper } from "./styled"
import ConfirmFail from "./ConfirmFail"
import ImagesRequest from "./ImagesRequest"
import Buttons from "./Buttons"
import UrgencyQuestion from "./Urgency"

interface ActionsProps {
  allAnswers: any
  newQuote: any
}

const Actions = ({ allAnswers, newQuote }: ActionsProps) => {
  const [quote, setQuote] = useRecoilState<QuoteState>(quoteState)
  const [searchParams] = useSearchParams()
  const paramsId = searchParams.get("id")

  const onSetLittaComments = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setQuote({
      ...quote,
      record: {
        ...quote.record,
        litta_comments: event.currentTarget.value
      }
    })
  }

  const onSetDate = (date: Date | null) => {
    setQuote((presentQuote) => ({
      ...presentQuote,
      record: {
        ...quote.record,
        next_contact_date_time: date
      }
    }))
  }

  return (
    <Wrapper>
      <Textarea
        label="Litta Comments"
        onChange={onSetLittaComments}
        value={quote.record.litta_comments}
      />

      {paramsId && (
        <Dropdowns>
          <ImagesRequest />
          <ConfirmFail />
        </Dropdowns>
      )}
      <div
        style={{
          columnGap: "20px",
          display: "grid",
          gridTemplateColumns: "1fr 1fr"
        }}
      >
        <DateTimePicker
          date={quote.record.next_contact_date_time ?? null}
          label="Next contact date"
          onSetDate={onSetDate}
        />
        <UrgencyQuestion />
      </div>
      <Buttons allAnswers={allAnswers} newQuote={newQuote} />
    </Wrapper>
  )
}

export default Actions
