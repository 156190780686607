import { useState } from "react"
import { SelectChangeEvent } from "@mui/material/Select"
import { useRecoilState } from "recoil"
import { useSelector } from "react-redux"

import { CollectionItem } from "@litta/types"
import {
  useAddItemGroupings,
  useFormBuilderForms,
  useServicePricings,
  usePageContent
} from "@litta/hooks"
import quoteState, { QuoteState } from "atoms/quote"

import { AdditionalItems, Dropdown } from "@litta/ui"
import { Wrapper } from "./styled"
import sortBy from "lodash/fp/sortBy"

const getLabel = (
  product: CollectionItem,
  isTrackedCustomer: boolean,
  isCommercialClientType: boolean
) => {
  let label = ""

  if (product.customer_load_title) {
    label += `${product.customer_load_title} `
  }

  label += product["LITTA Service"]

  if (!isTrackedCustomer && (product.number_bags || product.loading_time)) {
    if (product.number_bags) {
      label += ` (${product.number_bags} bags`

      if (product.loading_time) {
        label += ` / ${product.loading_time})`
      } else {
        label += ")"
      }
    } else {
      label += `(${product.loading_time})`
    }
  }

  label += ` - £${
    isCommercialClientType
      ? product?.price_ex_vat?.toFixed(2)
      : product.price_inc_vat.toFixed(2)
  }`

  return label
}

interface RubbishItemsProps {
  onAddCollectionItem: (item: CollectionItem) => void
  onRemoveCollectionItem: (item: CollectionItem) => void
}

const RubbishItems = ({
  onAddCollectionItem,
  onRemoveCollectionItem
}: RubbishItemsProps) => {
  const { pageContent } = usePageContent()
  const { formBuilderForms } = useFormBuilderForms()
  const { servicePricings } = useServicePricings()
  const [quote, setQuote] = useRecoilState<QuoteState>(quoteState)
  const [selectedLoadId, setSelectedLoadId] = useState("")

  const isCommercialClientType = quote?.record?.client_type === "commercial"

  const collectionItems: CollectionItem[] = useSelector(
    (state: any) => state.CollectionItemsReducer.items
  )

  const selectLoadText = pageContent.find(
    (item) => item.tag === "qb_select_load_ex_vat"
  )

  const formBuilderForm = formBuilderForms.find(
    (item) => item.tracked_customer?.[0] === quote.record.tracked_customer?.[0]
  )
  const { addItemGroupings } = useAddItemGroupings()

  const addItemGroupingId = formBuilderForm?.add_item_grouping?.[0]

  const addItemGrouping = addItemGroupings?.find(
    (item) => item.id === addItemGroupingId
  )

  const addItemGroupingServicePricings = servicePricings.filter((item) =>
    addItemGrouping?.service_pricing.includes(item.id)
  )

  const products: CollectionItem[] = sortBy("sort_product_order")(
    servicePricings.filter((item) => {
      // Load all load_increment type
      if (!quote.record.tracked_customer) {
        return item.type === "load_increment"
      }

      // If there is a tracked customer, we only load a smaller subset
      return (
        item.type === "load_increment_form" &&
        formBuilderForm?.name &&
        item.form_builder_forms?.includes(formBuilderForm.name)
      )
    })
  ) as unknown as CollectionItem[]

  const options = products.map((product: CollectionItem) => ({
    label: getLabel(
      product,
      Boolean(quote.record.tracked_customer),
      isCommercialClientType
    ),
    value: product.record_id
  }))

  const onChange = (event: SelectChangeEvent) => {
    const product = products.find(
      (item) => item.record_id === event.target.value
    )

    if (!product) {
      return
    }

    onAddCollectionItem(product)
    setSelectedLoadId(product.record_id)

    if (product.customer_advisory_text) {
      setQuote({
        ...quote,
        record: {
          ...quote.record,
          notes_to_customer: quote.record.notes_to_customer
            ? `${quote.record.notes_to_customer}\n\n${product.customer_advisory_text}`
            : product.customer_advisory_text
        }
      })
    }
  }

  const additionalItems = addItemGroupingServicePricings.map((item) => ({
    ...item,
    count:
      collectionItems.find((collectionItem) => collectionItem.id === item.id)
        ?.count ?? 0
  }))

  const onAddAdditionalItem = (servicePricingId: string) => {
    const servicePricing = additionalItems.find(
      (item) => item.id === servicePricingId
    )

    if (servicePricing) {
      onAddCollectionItem(servicePricing)
    }
  }

  const onRemoveAdditionalItem = (servicePricingId: string) => {
    const servicePricing = additionalItems.find(
      (item) => item.id === servicePricingId
    )

    if (servicePricing) {
      onRemoveCollectionItem(servicePricing)
    }
  }

  return (
    <Wrapper>
      <Dropdown
        label={
          isCommercialClientType
            ? (selectLoadText?.text as string)
            : "Select Load Sizes (prices  inc. VAT)"
        }
        onChange={onChange}
        options={options}
        value={selectedLoadId}
      />
      {addItemGrouping && selectedLoadId && isCommercialClientType && (
        <AdditionalItems
          icon={addItemGrouping?.icon_v2?.standard_url as string}
          onAddItem={onAddAdditionalItem}
          onDeleteItem={onRemoveAdditionalItem}
          servicePricings={additionalItems}
          title={addItemGrouping?.group_title}
        />
      )}
    </Wrapper>
  )
}

export default RubbishItems
