import { addItem, removeItem, addItems } from "./type"

const initialState = {
  items: [] as any
}

export const CollectionItemsReducer: any = (
  state = initialState,
  action: { type: string | null; data: any }
) => {
  if (action.type === addItem) {
    const items = [...state.items]

    const index = items.findIndex(
      (element) =>
        element.litta_service_linked === action.data.litta_service_linked
    )

    if (index === -1) {
      const data = { ...action.data }
      data.count = action.data.count || 1
      items.push(data)
    } else {
      items.splice(index, 1, {
        ...items[index],
        count: items[index].count + 1
      })
    }
    return {
      ...state,
      items
    }
  } else if (action.type === addItems) {
    return {
      ...state,
      items: action.data
    }
  } else if (action.type === removeItem) {
    const items = [...state.items]
    const index = items.findIndex(
      (element) =>
        element.litta_service_linked === action.data.litta_service_linked
    )

    if (items[index].count === 1) {
      items.splice(index, 1)
    } else {
      items.splice(index, 1, {
        ...items[index],
        count: items[index].count - 1
      })
    }
    return {
      ...state,
      items
    }
  }
  return state
}
