import { Label, Row, Title, Wrapper } from "./styled"
import quoteState from "atoms/quote"
import { useRecoilValue } from "recoil"
import { usePageContent } from "@litta/hooks"
import { useMemo } from "react"

const tagsMap = {
  newCustomer: "new_customer",
  repeatCustomer: "repeat_customer",
  returningProspect: "returning_prospect"
}

const UserHistory = () => {
  const quote = useRecoilValue(quoteState)
  const { pageContent } = usePageContent()

  const content = useMemo(() => {
    if (!quote.userHistory?.type) {
      return null
    }
    const tag = tagsMap[quote.userHistory?.type]

    if (!tag) {
      return null
    }

    return pageContent.find((item) => item.tag === tag)
  }, [pageContent, quote.userHistory])

  const lastJobSummary = useMemo(() => {
    if (!quote.userHistory?.lastJobSummary) {
      return null
    }

    return `${quote.userHistory?.lastJobSummary?.collectionDate} for £${quote.userHistory?.lastJobSummary?.lastStripeChargeAmount} at ${quote.userHistory?.lastJobSummary?.postcode}`
  }, [quote.userHistory?.lastJobSummary])

  if (!quote.userHistory) {
    return null
  }

  const previousEnquiries = pageContent.find(
    (item) => item.tag === "previous_enquiries"
  )

  const previousJobs = pageContent.find((item) => item.tag === "previous_jobs")

  const lastJob = pageContent.find((item) => item.tag === "last_job")

  return (
    <Wrapper
      background={content?.background_color}
      borderColor={content?.border_color}
    >
      <Title color={content?.color}>{content?.text}</Title>
      <Row>
        <Label color={content?.color}>
          {`${previousEnquiries?.text}: ${quote.userHistory.enquiries}`}
        </Label>
      </Row>
      <Row>
        <Label color={content?.color}>
          {`${previousJobs?.text}: ${quote.userHistory.jobs}`}
        </Label>
      </Row>
      {lastJobSummary && (
        <Row>
          <Label color={content?.color}>
            {`${lastJob?.text}: ${lastJobSummary}`}
          </Label>
        </Row>
      )}
    </Wrapper>
  )
}

export default UserHistory
