import { useEffect, useMemo, useState, memo } from "react"

import { TopRow, TotalPrice, TotalPriceSubtitle, Wrapper } from "./styled"
import { useRecoilValue } from "recoil"
import quoteState, { QuoteState, TotalQuotePriceState } from "atoms/quote"

import EstimatedProfit from "./EstimatedProfit"
import ManualAdjustment from "./ManualAdjustment"
import { usePayouts, useSelectedDayAndTime, usePageContent } from "@litta/hooks"
import Measurements from "./Measurements"

interface RightProps {
  isTouched: boolean
  manualAdjustment: number
  plusMinus: boolean
  setIsTouched: (isTouched: boolean) => void
  setManualAdjustment: (manualAdjustment: number) => void
  setPlusMinus: (plusMinus: boolean) => void
}
const Right = ({
  isTouched,
  manualAdjustment,
  plusMinus,
  setIsTouched,
  setManualAdjustment,
  setPlusMinus
}: RightProps) => {
  const { pageContent } = usePageContent()
  const quote = useRecoilValue<QuoteState>(quoteState)
  const totalQuotePrice = useRecoilValue<number>(TotalQuotePriceState)

  const [adjustmentType, setAdjustmentType] = useState<"auto" | "manual">(
    "auto"
  )
  const { selectedDayAndTime } = useSelectedDayAndTime()
  const {
    payouts: { recommendedPartnerPayout }
  } = usePayouts()

  useEffect(() => {
    setAdjustmentType(isTouched ? "manual" : "auto")
  }, [isTouched, setAdjustmentType])

  const onTogglePlusMinus = () => {
    setPlusMinus(!plusMinus)
    setIsTouched(true)
  }

  const onChange = (value: string) => {
    const adjustment = !value ? 0 : parseFloat(value)

    setManualAdjustment(adjustment)
    setIsTouched(true)
  }

  const totalPriceExVatText = pageContent.find(
    (item) => item.tag === "qb_total_price_ex_vat"
  )

  const feeSubtitle = useMemo(() => {
    if (!selectedDayAndTime.dayFee && !selectedDayAndTime.timeSlotFee) {
      return ""
    }
    let subtitle = "(includes"
    if (selectedDayAndTime.dayFee) {
      subtitle += ` £${selectedDayAndTime.dayFee.toFixed(2)} Weekend fee`
      if (selectedDayAndTime.timeSlotFee) {
        subtitle += ` and £${selectedDayAndTime.timeSlotFee.toFixed(
          2
        )} Timeslot fee`
      }
    } else {
      if (selectedDayAndTime.timeSlotFee) {
        subtitle += ` £${selectedDayAndTime.timeSlotFee.toFixed(
          2
        )} Timeslot fee`
      }
    }
    subtitle += ")"
    return subtitle
  }, [selectedDayAndTime.dayFee, selectedDayAndTime.timeSlotFee])

  const isCommercialClientType = quote?.record?.client_type === "commercial"

  const getTotalFee = (
    disposalFee: number | undefined,
    weekendFee: number | undefined,
    timeSlotFee: number | undefined,
    discount: number | undefined
  ): number => {
    const total =
      (disposalFee || 0) +
      (weekendFee || 0) +
      (timeSlotFee || 0) -
      (discount || 0)

    // To 2 decimal places
    return Math.round(total * 100) / 100
  }

  const totalFee = getTotalFee(
    totalQuotePrice,
    selectedDayAndTime.dayFee,
    selectedDayAndTime.timeSlotFee,
    quote.discount
  )

  return (
    <Wrapper>
      {!isCommercialClientType && (
        <TopRow>
          <EstimatedProfit
            recommendedPartnerPayout={recommendedPartnerPayout}
          />
        </TopRow>
      )}
      <TopRow>
        {!isCommercialClientType && (
          <>
            <TotalPrice>{`Total Price: £${(isNaN(totalFee)
              ? 0
              : totalFee
            ).toFixed(2)}`}</TotalPrice>
            {Boolean(feeSubtitle) && (
              <TotalPriceSubtitle>{feeSubtitle}</TotalPriceSubtitle>
            )}
          </>
        )}

        {isCommercialClientType && (
          <TotalPrice>{`${totalPriceExVatText?.text}: £${(isNaN(totalQuotePrice)
            ? 0
            : totalQuotePrice / 1.2
          ).toFixed(2)}`}</TotalPrice>
        )}
      </TopRow>

      <TopRow>
        <ManualAdjustment
          adjustmentType={adjustmentType}
          manualAdjustment={manualAdjustment}
          onChange={onChange}
          onTogglePlusMinus={onTogglePlusMinus}
          plusMinus={plusMinus}
        />
      </TopRow>
      <TopRow>
        <Measurements />
      </TopRow>
      <TopRow></TopRow>
    </Wrapper>
  )
}
export default memo(Right)
