import { removalType } from "./type"

const initialState = {
  removalType: ""
}

export const RemovalTypeReducer: any = (
  state = initialState,
  action: { type: any; data: any }
) => {
  if (action.type === removalType) {
    return {
      ...state,
      removalType: action.data
    }
  }
  return state
}
