import styled from "@emotion/styled"

export const Content = styled.div`
  display: grid;
  row-gap: 20px;
  width: 100%;

  & .inventory {
    max-width: none;
  }
`

export const Item = styled.div`
  margin-top: 20px;
`
