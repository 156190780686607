import axios from "axios"
import quoteState from "atoms/quote"
import { useRecoilState } from "recoil"

const useUpdateUserHistory = () => {
  const [quote, setQuote] = useRecoilState(quoteState)

  const updateUserHistory = async (email?: string) => {
    if (!quote.record.userDetails.email && !email) {
      return
    }

    const historyEmail = quote.record.userDetails.email || email

    try {
      const { data: userHistory } = await axios.get(
        `https://${process.env.REACT_APP_PAY_CLOUDFUNCTIONS_DOMAIN_NAME}.cloudfunctions.net/getHistory/${historyEmail}`
      )

      setQuote((presentQuote) => ({
        ...presentQuote,
        userHistory
      }))
    } catch (error) {
      setQuote((presentQuote) => ({
        ...presentQuote,
        userHistory: null
      }))
    }
  }

  return updateUserHistory
}

export default useUpdateUserHistory
