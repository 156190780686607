import { Box } from "@mui/material"
import "./Header.scss"

const Header = () => (
  <Box>
    <div className="headerContainer">
      <div className="imgDiv">
        <a href="https://litta.co" target="_blank" rel="noreferrer">
          <img src="/images/logo.png" alt="Return to the Litta website" />
        </a>
      </div>
    </div>
  </Box>
)

export default Header
