import { quoteId } from "./type"

const initialState = {
  id: ""
}

export const QuoteIdReducer: any = (
  state = initialState,
  action: { type: string | null; data: any }
) => {
  if (action.type === quoteId) {
    return {
      ...state,
      id: action.data
    }
  }
  return state
}
