import { Navigate } from "react-router-dom"
import { UserAuth, AuthContextType } from "./AuthContext"

const Protected = ({ children }) => {
  const { user, loadingAuth } = UserAuth() as AuthContextType

  if (!user && !loadingAuth) {
    return <Navigate to="/signin" />
  }

  if (user && !loadingAuth) return children

  return false
}

export default Protected
