import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { UserAuth, AuthContextType } from "AuthContext"
import { Spinner } from "@litta/ui"
import {
  SignInWrapper,
  LogInWrapper,
  LittaLogo,
  GoogleButton,
  GoogleIconWrapper,
  GoogleIcon,
  ButtonText,
  SinnerWrapper
} from "./styled"

const SignIn = () => {
  const { googleSignIn, user, loadingAuth } = UserAuth() as AuthContextType
  const navigate = useNavigate()

  const onGoogleSignIn = async () => {
    googleSignIn()
  }

  useEffect(() => {
    if (user != null) {
      navigate("/")
    }
  }, [user])

  if (loadingAuth)
    return (
      <SinnerWrapper>
        <Spinner loading={loadingAuth} />
      </SinnerWrapper>
    )

  return (
    <SignInWrapper>
      <LogInWrapper>
        <LittaLogo src={require("../../images/LittaLogo.svg").default} />
        <GoogleButton onClick={onGoogleSignIn}>
          <GoogleIconWrapper>
            <GoogleIcon src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg" />
          </GoogleIconWrapper>
          <ButtonText>
            <b>{"Sign in with google"}</b>
          </ButtonText>
        </GoogleButton>
      </LogInWrapper>
    </SignInWrapper>
  )
}

export default SignIn
