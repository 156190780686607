import styled from "@emotion/styled"
import { colors } from "@litta/ui"

export const SignInWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`

export const LogInWrapper = styled.div`
  display: flex;
  flex-direction: column;
`
export const LittaLogo = styled.img`
  margin-bottom: 30px;
`

export const SinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`

export const GoogleButton = styled.div`
  cursor: pointer;
  width: 184px;
  height: 42px;
  background-color: ${colors.cornflowerBlue};
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);

  &:hover {
    box-shadow: 0 0 6px ${colors.cornflowerBlue};
  }
  &:active {
    background: ${colors.blueRibbon};
  }
`
export const GoogleIconWrapper = styled.div`
  position: absolute;
  margin-top: 1px;
  margin-left: 1px;
  width: 40px;
  height: 40px;
  border-radius: 2px;
  background-color: ${colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
`
export const GoogleIcon = styled.img`
  position: absolute;
  width: 18px;
  height: 18px;
`
export const ButtonText = styled.p`
  float: right;
  margin: 11px 11px 0 0;
  color: ${colors.white};
  font-size: 14px;
  letter-spacing: 0.2px;
  font-family: "Roboto";
`
