import { leadTime } from "./type"

const initialState = {
  leadTime: ""
}

export const LeadTimeReducer: any = (
  state = initialState,
  action: { type: string | null; data: any }
) => {
  if (action.type === leadTime) {
    return {
      ...state,
      leadTime: action.data
    }
  }
  return state
}
