import { address } from "./type"

const initialState = {
  address: ""
}

export const AddressReducer: any = (
  state = initialState,
  action: { type: string | null; data: any }
) => {
  if (action.type === address) {
    return {
      ...state,
      address: action.data
    }
  }
  return state
}
