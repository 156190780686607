import { updateResponses, deleteResponse, setAnswers } from "./type"

const initialState = {}

export const AnswersReducer: any = (
  state = initialState,
  action: { type: string | null; data: any }
) => {
  if (action.type === updateResponses) {
    return {
      ...state,
      [action.data.rule]: action.data
    }
  } else if (action.type === deleteResponse) {
    const answers = { ...state }
    delete answers[action.data]

    return answers
  } else if (action.type === setAnswers) {
    return action.data.reduce(
      (acc, answer) => ({
        ...acc,
        [answer.rule]: answer
      }),
      {}
    )
  }

  return state
}
