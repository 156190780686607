import { useEffect, useState } from "react"
import { ErrorMessage, Row, Wrapper } from "./styled"
import { Button, Input } from "@litta/ui"
import { useNavigate } from "react-router-dom"
import { useBookOrderByWebReference } from "@litta/hooks"

const PREFIX = "WEBQ"

const LoadQuote = () => {
  const [webReference, setWebReference] = useState(PREFIX)
  const navigate = useNavigate()
  const [
    getBookOrderByWebReference,
    { bookOrderByWebReference, error, loading }
  ] = useBookOrderByWebReference({ webReference })

  const onChange = (value: string) => {
    setWebReference(PREFIX + value.substring(PREFIX.length))
  }

  useEffect(() => {
    if (bookOrderByWebReference) {
      navigate(`/?id=${bookOrderByWebReference.recordId}`)
    }
  }, [bookOrderByWebReference, navigate])

  const onLoadQuote = () => {
    getBookOrderByWebReference()
  }

  return (
    <Wrapper>
      <Row>
        <Input onChange={onChange} type="text" value={webReference} />
        <Button loading={loading} label="Load" onClick={onLoadQuote} />
      </Row>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </Wrapper>
  )
}

export default LoadQuote
