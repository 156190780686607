import { useEffect, useState } from "react"
import { PromoCode } from "@litta/types"
import getPromoCodes from "dbQueries/getPromoCodes"
import { atom, useRecoilState } from "recoil"

const promoCodesState = atom<PromoCode[]>({
  default: [],
  key: "promoCodes"
})

const usePromoCodes = () => {
  const [promoCodes, setPromoCodes] =
    useRecoilState<PromoCode[]>(promoCodesState)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    const getData = async () => {
      setError("")
      setLoading(true)
      const response: PromoCode[] = await getPromoCodes()
      setLoading(false)

      setPromoCodes(response)

      if (response) {
        setError(null)
      } else {
        setError("This discount code is invalid")
      }
    }

    getData()
  }, [setLoading, setPromoCodes])

  return {
    error,
    loading,
    promoCodes
  }
}

export default usePromoCodes
