import {
  updateResponses,
  deleteResponse,
  setAnswers as setAnswersType
} from "./type"

export const updateAnswers: any = (update: any) => {
  return {
    type: updateResponses,
    data: update
  }
}

export const deleteAnswer: any = (update: number) => {
  return {
    type: deleteResponse,
    data: update
  }
}

export const setAnswers: any = (answers: any) => {
  return {
    type: setAnswersType,
    data: answers
  }
}
