import { getDocs, collection, query, where } from "firebase/firestore/lite"
import { PromoCode } from "@litta/types"
import db from "@litta/firebase"

const getPromoCodes = async (): Promise<PromoCode[]> => {
  const q = query(
    collection(db, "promo_codes"),
    where("show_in_quotebuilder", "==", true)
  )
  const promoCodesSnapshot = await getDocs(q)

  const promoCodesList: PromoCode[] = promoCodesSnapshot.docs.map((doc) =>
    doc.data()
  ) as PromoCode[]

  return promoCodesList
}

export default getPromoCodes
