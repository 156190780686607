import { Fragment, useEffect, useState } from "react"
import {
  ActiveCodes,
  DatePicker,
  Inventory,
  ConfirmationQuestionsQuoteBuilder
} from "@litta/ui"
import { useSelector } from "react-redux"
import {
  useHolidays,
  useConfirmationQuestions,
  usePageContent,
  usePayouts
} from "@litta/hooks"
import { useRecoilValue } from "recoil"
import quoteState, { QuoteState } from "atoms/quote"
import { CollectionItem } from "@litta/types"

import "./main.scss"

import { Content, Item } from "./styled"
import usePromoCodes from "hooks/usePromoCodes"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import Summary from "./Summary"
import Actions from "./Actions"
import RecommendedPayouts from "./RecommendedPayouts"
import { useSearchParams } from "react-router-dom"

interface RightProps {
  manualAdjustment: number
  newQuote: any
  onAddCollectionItem: (item: CollectionItem) => void
  onRemoveCollectionItem: (item: CollectionItem) => void
  plusMinus: boolean
  postCodeSurge: any
  recordIdLoading: boolean
  setIsTouched: (isTouched: boolean) => void
  setManualAdjustment: (manualAdjustment: number) => void
  setPlusMinus: (plusMinus: boolean) => void
  nextAvailableDate: string
}

const Right = ({
  nextAvailableDate,
  manualAdjustment,
  newQuote,
  onAddCollectionItem,
  onRemoveCollectionItem,
  plusMinus,
  postCodeSurge,
  recordIdLoading,
  setIsTouched,
  setManualAdjustment,
  setPlusMinus
}: RightProps) => {
  const [searchParams] = useSearchParams()
  const idParam = searchParams.get("id")

  const quote = useRecoilValue<QuoteState>(quoteState)
  const [open, setOpen] = useState<boolean>(false)
  const { promoCodes } = usePromoCodes()
  const collectionItems = useSelector(
    (state: any) => state.CollectionItemsReducer.items
  )

  const [allAnswers, setAllAnswers] = useState<{}>({})

  const { pageContent } = usePageContent()

  const slotStatusLabels = {
    full: pageContent.find((item) => item.tag === "full")?.text ?? "",
    last_slot: pageContent.find((item) => item.tag === "last_slot")?.text ?? "",
    two_slots_left:
      pageContent.find((item) => item.tag === "two_slots_left")?.text ?? ""
  }

  useEffect(() => {
    setOpen(true)
  }, [collectionItems, setOpen])

  const { holidays } = useHolidays()

  const {
    payouts: { distribDesc, recommendedCustomerSurgePrice }
  } = usePayouts()

  const [responses, setResponses] = useState<any[]>([])

  const confirmationQuestions = (useConfirmationQuestions()?.data ?? []).filter(
    (item) => item.platform.includes("quotebuilder") && item.active
  )

  const onUpdateAnswers = (value: string, index: number) => {
    const fieldName = confirmationQuestions?.[index].field_name
    const collectAnswers = { ...allAnswers }
    collectAnswers[fieldName] = value
    setAllAnswers(collectAnswers)

    const newResponses = [...responses]
    newResponses[index] = value
    setResponses(newResponses)
  }

  const onSetRecommendedCustomerSurgePrice = () => {
    setManualAdjustment(
      parseFloat((quote.totalPrice - recommendedCustomerSurgePrice).toFixed(2))
    )
    setPlusMinus(false)
    setIsTouched(false)
  }

  const isCommercialClientType = quote?.record?.client_type === "commercial"

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className="right">
        <Content>
          {!isCommercialClientType && (
            <RecommendedPayouts
              items={distribDesc}
              onClick={onSetRecommendedCustomerSurgePrice}
              recommendedCustomerSurgePrice={recommendedCustomerSurgePrice}
            />
          )}

          <Actions allAnswers={allAnswers} newQuote={newQuote} />
          {!quote.record.paid && (
            <Fragment>
              <Inventory
                addCollectionItem={onAddCollectionItem}
                open={open && collectionItems.length}
                inventory={collectionItems}
                removeCollectionItem={onRemoveCollectionItem}
                setOpen={setOpen}
                showPrices
              />

              <DatePicker
                postCode={quote.record?.address_details?.postcode || ""}
                hasCustomSlot
                holidays={holidays}
                isBusiness={quote.record.enquiry_type !== "residential"}
                isSmall
                isSaturdayCoverage={postCodeSurge?.saturday_coverage}
                isSundayCoverage={postCodeSurge?.sunday_coverage}
                nextAvailableDate={nextAvailableDate}
                slotsFilterName="quotebuilder.litta"
                slotStatusLabels={slotStatusLabels}
                slotTier={postCodeSurge?.postcode_timeslot_tier}
                title="What day is best for you?"
                customerType={quote.record.enquiry_type}
              />
            </Fragment>
          )}
          <Summary
            allAnswers={allAnswers}
            manualAdjustment={manualAdjustment}
            plusMinus={plusMinus}
            recordIdLoading={recordIdLoading}
            salesOwner={newQuote.owner}
            setAllAnswers={setAllAnswers}
          />

          {!quote.record.paid && idParam && (
            <Item>
              <ConfirmationQuestionsQuoteBuilder
                onUpdateAnswers={onUpdateAnswers}
                questions={confirmationQuestions}
                responses={responses}
              />
            </Item>
          )}
          <Item>
            <ActiveCodes promoCodes={promoCodes} />
          </Item>
        </Content>
      </div>
    </LocalizationProvider>
  )
}

export default Right
