import { atom, selector } from "recoil"
import {
  AddressDetails,
  CustomerType,
  SlotPickerTimeSlot,
  UserDetails
} from "@litta/types"

export interface Record {
  paid?: boolean
  B2B?: string
  TIME_SLOT_FEE?: number
  action_status?: string
  address_details: AddressDetails
  answers: any[]
  business_name: string
  call_attempt_count?: string
  client_type?: string
  enquiry_type: CustomerType
  description: string
  fail_reason?: string
  images: any[]
  litta_comments: string
  next_contact_date_time?: Date | null
  notes_to_customer: string
  quote_ref: string
  referral_indicator?: string
  relationship_manager?: string
  request_images?: string
  selectedDate: string
  selectedLastBookingTime?: Date | null
  selectedSlot: SlotPickerTimeSlot | string
  selected_slot_id: string
  status?: string
  timestamp?: {
    _nanoseconds: number
    _seconds: number
  }
  tracked_customer?: string[]
  userDetails: UserDetails
  when_required?: string
}

export interface UserHistory {
  enquiries: number
  jobs: number
  jobs_value: number
  lastJobSummary: {
    collectionDate: string
    lastJobQuoteValue: number
    lastStripeChargeAmount: number
    postcode: string
  } | null
  quotes_value: number
  type: "newCustomernewCustomer" | "repeatCustomer" | "returningProspect" | null
  userId: string
}

export interface QuoteState {
  discount: number
  loading: boolean
  quoteType: string
  recommendedPartnerPayout: number
  record: Record
  totalPrice: number
  totalPriceAdjusted: number
  totalPriceAdjustedWihoutFees: number
  userHistory: UserHistory | null
  paid?: boolean
}

export const DEFAULT_ADDRESS_DETAILS = {
  building_name: "",
  building_number: "",
  country: "",
  county: "",
  formatted_address: [],
  latitude: 0,
  line_1: "",
  line_2: "",
  line_3: "",
  line_4: "",
  longitude: 0,
  postcode: "",
  sub_building_name: "",
  sub_building_number: "",
  thoroughfare: "",
  town_or_city: ""
}

const DEFAULT_QUOTE_STATE: QuoteState = {
  discount: 0,
  loading: false,
  quoteType: "create",
  recommendedPartnerPayout: 0,
  record: {
    address_details: DEFAULT_ADDRESS_DETAILS,
    answers: [],
    business_name: "",
    enquiry_type: "residential",
    description: "",
    images: [],
    litta_comments: "",
    notes_to_customer: "",
    quote_ref: "",
    selectedDate: "",
    selectedSlot: "",
    selected_slot_id: "",
    userDetails: {
      email: "",
      mobilenumber: "",
      name: ""
    }
  },
  totalPrice: 0,
  totalPriceAdjusted: 0,
  totalPriceAdjustedWihoutFees: 0,
  userHistory: null
}

const quoteState = atom<QuoteState>({
  default: DEFAULT_QUOTE_STATE,
  key: "quote"
})

export const isQuoteValidState = selector({
  key: "isQuoteValid",
  get: ({ get }) => {
    const quote = get(quoteState)

    const quoteErrors = {
      enquiry_type:
        quote.record.client_type !== "commercial" &&
        quote.record.enquiry_type !== "residential" &&
        quote.record.enquiry_type !== "business"
    }

    const isQuoteValid = Object.values(quoteErrors).every((item) => !item)

    return {
      isQuoteValid,
      quoteErrors
    }
  }
})

export const TotalQuotePriceState = atom<number>({
  default: 0,
  key: "totalQuotePriceState"
})

export default quoteState
