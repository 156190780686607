import { Wrapper } from "./styled"
import { useImagesRequest } from "@litta/hooks"
import { Dropdown } from "@litta/ui"
import { SelectChangeEvent } from "@mui/material/Select"
import quoteState, { QuoteState } from "atoms/quote"
import { useRecoilState } from "recoil"

const ImagesRequest = () => {
  const [quote, setQuote] = useRecoilState<QuoteState>(quoteState)

  const { imagesRequest } = useImagesRequest()

  const onChangeImagesRequest = (event: SelectChangeEvent) => {
    if (event.target.value === "") {
      setQuote({
        ...quote,
        record: {
          ...quote.record,
          request_images: undefined
        }
      })
      return
    }

    setQuote({
      ...quote,
      record: {
        ...quote.record,
        request_images: event.target.value
      }
    })
  }

  const options = [
    { label: " ", value: "" },
    ...imagesRequest.map(({ name }) => ({
      label: name,
      value: name
    }))
  ]

  return (
    <Wrapper>
      <Dropdown
        label="Request Images"
        onChange={onChangeImagesRequest}
        options={options}
        value={quote.record.request_images ?? ""}
      />
    </Wrapper>
  )
}

export default ImagesRequest
