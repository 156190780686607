import { useEffect, useState } from "react"
import { Wrapper } from "./styled"
import { useUrgencyQuestion } from "@litta/hooks"
import { Dropdown } from "@litta/ui"
import { SelectChangeEvent } from "@mui/material/Select"
import quoteState, { QuoteState } from "atoms/quote"
import { useRecoilState } from "recoil"

const UrgencyQuestion = () => {
  const [quote, setQuote] = useRecoilState<QuoteState>(quoteState)

  const [howSoonDoYouNeedThisService, setHowSoonDoYouNeedThisService] =
    useState<any>([])
  const { data: usageBucketsData, isLoading: isLoadingUrgencyQuestion } =
    useUrgencyQuestion()

  useEffect(() => {
    if (!isLoadingUrgencyQuestion && usageBucketsData) {
      const usageBuckets = usageBucketsData
        .filter((bucket: any) => bucket.type === "urgency")
        .map((bucket: any) => bucket.options[0])
      setHowSoonDoYouNeedThisService(usageBuckets)
    }
  }, [isLoadingUrgencyQuestion])

  const onChangeUrgencyQuestion = (event: SelectChangeEvent) => {
    if (event.target.value === "") {
      setQuote({
        ...quote,
        record: {
          ...quote.record,
          when_required: ""
        }
      })
      return
    }

    setQuote({
      ...quote,
      record: {
        ...quote.record,
        when_required: event.target.value
      }
    })
  }

  const options = [
    { label: " ", value: "" },
    ...howSoonDoYouNeedThisService.map((option) => ({
      label: option,
      value: option
    }))
  ]
  return (
    <Wrapper>
      <Dropdown
        label="Urgency"
        onChange={onChangeUrgencyQuestion}
        options={options}
        value={quote.record.when_required ?? ""}
      />
    </Wrapper>
  )
}

export default UrgencyQuestion
