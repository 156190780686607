import { Button } from "@litta/ui"
import { Items, Wrapper } from "./styled"

interface RecommendedPayoutsProps {
  items: string[]
  onClick: () => void
  recommendedCustomerSurgePrice: number
}

const RecommendedPayouts = ({
  items,
  onClick,
  recommendedCustomerSurgePrice
}: RecommendedPayoutsProps) => {
  return (
    <Wrapper>
      <h2>{`Recommendation: ${
        recommendedCustomerSurgePrice
          ? `£${recommendedCustomerSurgePrice.toFixed(2)}`
          : "N/A"
      }`}</h2>
      <Items>
        {items?.map((item, index) => (
          <h3 key={`recommended-payouts-${index}`}>{item}</h3>
        ))}
      </Items>
      <Button
        disabled={recommendedCustomerSurgePrice === null}
        label="Accept Recommended Price"
        onClick={onClick}
      />
    </Wrapper>
  )
}

export default RecommendedPayouts
