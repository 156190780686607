import { category } from "./type"

const initialState = {
  category: ""
}

export const CategoryReducer: any = (
  state = initialState,
  action: { type: any; data: any }
) => {
  if (action.type === category) {
    return {
      ...state,
      category: action.data
    }
  }
  return state
}
