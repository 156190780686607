import { useEffect, useState } from "react"
import { getFunctions, httpsCallable } from "firebase/functions"
import { useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"
import { useRecoilState, useRecoilValue } from "recoil"
import quoteState, { QuoteState, isQuoteValidState } from "atoms/quote"
import {
  ConfirmationQuestions,
  PaymentSuccessful,
  SummaryWithPay as SummaryComponent
} from "@litta/ui"
import { CollectionItem } from "@litta/types"
import {
  useBookOrderByWebReference,
  useConfirmationQuestions,
  useErrorMessages,
  usePageContent,
  usePromoCode,
  useSelectedDayAndTime
} from "@litta/hooks"
import { SuccessMessage } from "./styled"
import { Item } from "../styled"
import { ElementsConsumer } from "@stripe/react-stripe-js"
import { TotalQuotePriceState } from "atoms/quote"

interface SummaryProps {
  allAnswers: any
  manualAdjustment: number
  plusMinus: boolean
  recordIdLoading: boolean
  salesOwner: string
  setAllAnswers: (allAnswers: any) => void
}
const getItemObject = (item: any) => {
  const product = { ...item }
  product.id = product.id ? product.id : product.record_id
  return product
}
const isDismantle = (item: { type: string }): boolean =>
  item.type === "dismantle"
const isDisconnect = (item: { type: string }) => item.type === "disconnect"
const Summary = ({
  allAnswers,
  manualAdjustment,
  plusMinus,
  recordIdLoading,
  salesOwner,
  setAllAnswers
}: SummaryProps) => {
  const [searchParams] = useSearchParams()
  const recordId = searchParams.get("id")
  const [quote, setQuote] = useRecoilState<QuoteState>(quoteState)
  const totalQuotePrice = useRecoilValue<number>(TotalQuotePriceState)
  const [code, setCode] = useState<string>("")
  const [success, setSuccess] = useState<boolean>(false)
  const [responses, setResponses] = useState<any[]>([])

  const [paymentError, setPaymentError] = useState<string>("")

  const { error: promoCodeError, promoCode } = usePromoCode({ code })
  const { errorMessages } = useErrorMessages({ platform: "book.litta" })
  const internalServerError = errorMessages.find(
    (message) => message.Name === "internal_server_error"
  )
  const addressIncompleteErrorMessage = errorMessages.find(
    (message) => message.Name === "address_required"
  )
  const { pageContent } = usePageContent()
  const { selectedDayAndTime } = useSelectedDayAndTime()
  const { isQuoteValid } = useRecoilValue(isQuoteValidState)
  const addPromoCodeLabel =
    pageContent.find((item) => item.tag === "add_promo_code")?.text ?? ""
  const netTotalText =
    pageContent.find((item) => item.tag === "net_total")?.text ?? ""
  const vatText = pageContent.find((item) => item.tag === "vat")?.text ?? ""
  const [
    getBookOrderByWebReference,
    { bookOrderByWebReference, loading: bookOrderByWebReferenceLoading }
  ] = useBookOrderByWebReference({ webReference: quote.record.quote_ref })
  const confirmationQuestions = (useConfirmationQuestions()?.data ?? []).filter(
    (item) => item?.platform?.includes("quotebuilder") && item.active
  )
  useEffect(() => {
    if (quote.record.quote_ref) {
      getBookOrderByWebReference()
    }
  }, [quote.record.quote_ref])
  const address = useSelector((state: any) => state.AddressReducer.address)
  const collectionItems = useSelector(
    (state: any) => state.CollectionItemsReducer.items
  )
  const onGetClientSecret = async (paymentMethodId?: string) => {
    try {
      const functions = getFunctions()
      // const bookCharge = httpsCallable(functions, "book_charge_IOS")

      const sendAnswer = httpsCallable(functions, "submit_confirmation")
      let fees: any = {}

      if (selectedDayAndTime.timeSlotId === "custom") {
        fees = {
          ...fees,
          TIME_SLOT_FEE: selectedDayAndTime.timeSlotFee,
          WEEK_DAY_FEE: selectedDayAndTime.dayFee,
          selectedSlot: selectedDayAndTime.timeSlot
        }
      }

      const dismantle = collectionItems
        .filter((item: CollectionItem) => isDismantle(item))
        .map((item: CollectionItem) => getItemObject(item))
      const disconnect = collectionItems
        .filter((item: CollectionItem) => isDisconnect(item))
        .map((item: CollectionItem) => getItemObject(item))
      const items = collectionItems
        .filter(
          (item: CollectionItem) => !isDismantle(item) && !isDisconnect(item)
        )
        .map((item: CollectionItem) => getItemObject(item))

      const bookChargeResult: any = await fetch(
        `https://${process.env.REACT_APP_PAY_CLOUDFUNCTIONS_DOMAIN_NAME}.cloudfunctions.net/book_charge_IOS`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          method: "POST",
          body: JSON.stringify({
            data: {
              ...quote.record,
              ...fees,
              total: totalQuotePrice,
              address,
              business_name: quote.record.business_name,
              collection_date: selectedDayAndTime.day
                ?.split("-")
                .reverse()
                .join("-"),
              coupon: promoCode?.code?.toUpperCase(),
              disconnect,
              dismantle,
              enquiry_type: quote.record.enquiry_type,
              items,
              manual_adjustment: plusMinus
                ? manualAdjustment
                : -manualAdjustment,
              moto: true,
              owner: salesOwner,
              payment_method: paymentMethodId,
              platform: "quotebuilder",
              selected_slot_id: selectedDayAndTime.timeSlotId
            }
          })
        }
      )
      const responseBookCharge = await bookChargeResult.json()

      if (bookChargeResult.status === 400) {
        const errorResponse = JSON.parse(JSON.stringify(responseBookCharge))
        setPaymentError(errorResponse.error.message)
        throw JSON.stringify({
          status: 400,
          message: errorResponse.error.message
        })
      }
      if (bookChargeResult.status > 400) {
        const errMessage =
          internalServerError?.error_message ||
          "Something went wrong. Please call us to complete your booking"

        setPaymentError(errMessage)
        throw JSON.stringify({
          status: 400,
          message: errMessage
        })
      }

      if (Object.keys(allAnswers).length) {
        await sendAnswer({
          id: recordId,
          fields: allAnswers
        })
      }
      return responseBookCharge.result.client_secret
    } catch (err: any) {
      const errorFormatted = JSON.parse(err)

      throw new Error(errorFormatted.message)
    }
  }

  const onSuccess = () => {
    setSuccess(true)

    setQuote({
      ...quote,
      record: {
        ...quote.record,
        paid: true
      }
    })
  }
  if (!recordId || recordIdLoading || bookOrderByWebReferenceLoading) {
    return null
  }
  if (success || bookOrderByWebReference?.paid) {
    return (
      <SuccessMessage>
        <PaymentSuccessful />
      </SuccessMessage>
    )
  }
  const {
    record: { address_details: addressDetails }
  } = quote
  const isAddressIncomplete =
    !addressDetails?.line_1 ||
    !addressDetails?.town_or_city ||
    !addressDetails?.postcode
  const onUpdateAnswers = (value: string, index: number) => {
    const fieldName = confirmationQuestions?.[index].field_name
    const collectAnswers = { ...allAnswers }
    collectAnswers[fieldName] = value
    setAllAnswers(collectAnswers)
    const newResponses = [...responses]
    newResponses[index] = value
    setResponses(newResponses)
  }

  const isBusiness = quote.record.enquiry_type !== "residential"

  return (
    <>
      <ElementsConsumer>
        {({ stripe, elements }) => (
          <SummaryComponent
            addressIncompleteErrorMessage={
              addressIncompleteErrorMessage?.error_message ?? ""
            }
            chargeData={{ selected_slot_id: selectedDayAndTime.timeSlotId }}
            disabled={!address || !selectedDayAndTime.timeSlot || !isQuoteValid}
            discount={quote.discount}
            disposalFee={totalQuotePrice}
            elements={elements}
            getClientSecret={onGetClientSecret}
            isAddressIncomplete={isAddressIncomplete}
            isBusiness={isBusiness}
            isLarge
            isMoto
            label={addPromoCodeLabel}
            netTotalText={netTotalText}
            onSetPromoCode={setCode}
            onSuccess={onSuccess}
            paymentRequestLabel="Book with LITTA"
            promoCodeError={promoCodeError}
            serverErrorMessage={paymentError}
            stripe={stripe}
            timeslotFee={selectedDayAndTime.timeSlotFee}
            vatText={vatText}
            weekendFee={selectedDayAndTime.dayFee}
          />
        )}
      </ElementsConsumer>
      <Item>
        <ConfirmationQuestions
          isQuoteBuilder
          onUpdateAnswers={onUpdateAnswers}
          questions={confirmationQuestions}
          responses={responses}
        />
      </Item>
    </>
  )
}
export default Summary
