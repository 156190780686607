import styled from "@emotion/styled"
import { colors } from "@litta/ui"

export const Wrapper = styled.div`
  border-top: 10px solid ${colors.yourPink};
  display: grid;
  padding: 0 20px;
  position: relative;
  row-gap: 20px;
  width: 100vw;
`

export const MainWrapper = styled.div`
  column-gap: 40px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  min-height: 400px;
  padding: 10px 20px 20px;
  z-index: -1;
`

export const CustomerType = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  padding-right: 20px;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 8px;

  input {
    width: 100%;
  }
`

export const ToggleInputWrapper = styled.div`
  display: flex;
  margin-bottom: 10px;
  border: 1px solid ${colors.athensGray};
  background-color: ${colors.athensGray};
  border-radius: 30px;
  min-width: 300px;
`
export const ToggleButton = styled.button<{ selected: boolean }>`
  border-radius: 30px;
  width: 50%;
  border: none;
  padding: 10px;
  background-color: ${(props) =>
    props.selected ? colors.robinsEggBlue : "none"};
  color: ${(props) => (props.selected ? colors.white : colors.osloGray)};
  cursor: pointer;
`

export const InputSpacer = styled.div`
  height: 40px;
`

export const ClientTypeBar = styled.div`
  align-items: center;
  background: ${colors.yellow};
  display: grid;
  height: 40px;
  left: 0;
  justify-items: center;
  position: absolute;
  text-transform: uppercase;
  top: 0;
  width: 100vw;
`

export const LogOutWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const LogOutContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const LogOut = styled.span<{
  cursor?: string
  marginLeft?: string
}>`
  color: ${colors.gray};
  ${({ cursor }) => cursor && `cursor: ${cursor};`}
  ${({ marginLeft }) => marginLeft && `margin-left: ${marginLeft};`}
`

export const QuoteBuilderTitle = styled.h1`
  margin: 0;
`
