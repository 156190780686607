import styled from "@emotion/styled"

export const Wrapper = styled.div<{
  background?: string
  borderColor?: string
}>`
  background: ${({ background }) => background};
  border: 2px solid ${({ borderColor }) => borderColor};
  display: grid;
  row-gap: 10px;
  justify-items: center;
  padding: 20px;
  width: 100%;
`

export const Title = styled.h3<{ color?: string }>`
  font-family: Poppins, sans-serif;
`

export const Label = styled.label<{ color?: string }>``

export const Row = styled.div`
  column-gap: 10px;
  display: grid;
  justify-items: center;
`
