import { useMemo } from "react"
import { useSelector } from "react-redux"

import { CollectionItem } from "@litta/types"

import { Wrapper } from "./styled"

const Measurements = () => {
  const collectionItems = useSelector(
    (state: any) => state.CollectionItemsReducer.items
  )

  const measurements = useMemo(
    () =>
      collectionItems.reduce(
        (acc: any, item: CollectionItem) => ({
          volume: acc.volume + (item.volume_total ?? 0) * item.count,
          weight: acc.weight + (item.avg_weight_kg ?? 0) * item.count
        }),
        { volume: 0, weight: 0 }
      ),
    [collectionItems]
  )

  return (
    <Wrapper>
      <h2>
        {`Volume: ${measurements.volume.toFixed(2)} y`}
        <sup>{"3"}</sup>
      </h2>
      <h2>{`Weight: ${measurements.weight.toFixed(2)} kg`}</h2>
    </Wrapper>
  )
}

export default Measurements
