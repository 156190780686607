import { Input, PlusMinusDeleteButton } from "@litta/ui"
import { PlusMinusButtons, Row, Wrapper } from "./styled"
import {} from "../styled"

const ManualAdjustment = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  adjustmentType,
  manualAdjustment,
  onChange,
  onTogglePlusMinus,
  plusMinus
}) => (
  <Wrapper>
    <Row>
      <h2>{`Adjustment: ${!plusMinus ? "-" : ""}£`}</h2>
      <Input type="number" onChange={onChange} value={manualAdjustment} />
      <PlusMinusButtons>
        <PlusMinusDeleteButton
          buttonType="minus"
          disabled={!plusMinus}
          onClick={onTogglePlusMinus}
        />
        <PlusMinusDeleteButton
          buttonType="plus"
          disabled={plusMinus}
          onClick={onTogglePlusMinus}
        />
      </PlusMinusButtons>
    </Row>
    <Row>
      <h3>{`Adjustment Type: ${adjustmentType}`}</h3>
    </Row>
  </Wrapper>
)

export default ManualAdjustment
